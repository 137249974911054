<template>
    <div class="artinvest-hotels">
        <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-2" style="cursor: pointer">
      <v-icon color="#077a2d" class="ml-2" size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
      <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
        {{ "Art-Invest Hotels" }}
      </v-toolbar-title>
    </v-toolbar>

    </div>
</template>

<script>

import { ref, computed } from 'vue';  // Import computed here
import { useRouter } from 'vue-router'; // Import useRouter
import Loader from '@/components/Loader.vue';

export default {
    name: 'ArtInvestHotels',
    setup() {
    const showLoader = ref(true);
    const router = useRouter(); // Initialize useRouter

    const goBack = () => {
      router.go(-1);
    };

    return {
      showLoader,
      goBack,
    };
  }
};
</script>

<style scoped>
/* Your component's CSS code goes here */
</style>