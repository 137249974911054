<template>
  <div>
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-4">
      <v-icon style="margin-left: 10px" size="40" @click="goBack">
        mdi-arrow-left-bold-circle
      </v-icon>
      <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
        {{ toobarTitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col v-for="(card, index) in cards" :key="index" cols="12" sm="6" md="4">
          <v-card class="elegant-card"
            :class="{ 'active-card': selectedCard === index, 'dim-card': selectedCard !== index && !card.hover }"
            @mouseover="hoverCard(index, true)" @mouseleave="hoverCard(index, false)" @click="selectCard(index)">
            <img :src="card.image" class="card-image" :class="{ 'card-image-hover': card.hover }" />
            <div class="card-bottom">
              <v-card-title class="card-title">{{ card.text }}</v-card-title>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted } from "vue";
import Loader from "@/components/Loader.vue";
import { getToolBarLogo } from "@/assets/Branding/branding.js";
import { useRouter } from "vue-router";
import oldwall1905 from "@/assets/Branding/Artinvest/OldWall/oldwall1905.jpg";
import oldwall40 from "@/assets/Branding/Artinvest/OldWall/oldwall40.jpg";
import oldwall38 from "@/assets/Branding/Artinvest/OldWall/oldwall38.jpg";
import oldwall232 from "@/assets/Branding/Artinvest/OldWall/oldwall232.jpg";

export default {
  name: 'OldWallView',
  components: {
    Loader,
  },
  setup() {
    const showLoader = ref(true);
    const logo = ref("");
    const router = useRouter();
    const selectedCard = ref(0);
    const toobarTitle = ref("A l t e r W a l l H i s t o r i e");
    const cards = ref([
      { text: "Alter Wall Historie", image: oldwall1905, hover: false },
      { text: "Alter Wall 2-32", image: oldwall232, hover: false },
      { text: "Alter Wall 38", image: oldwall38, hover: false },
      { text: "Alter Wall 40", image: oldwall40, hover: false },
    ]);

    const goBack = () => {
      router.go(-1);
    };

    const hoverCard = (index, hover) => {
      cards.value[index].hover = hover;
    };

    const selectCard = (index) => {
      if (index === 0) {
        router.push({ name: "OldWallHistory" }).catch((err) => console.error("Routing error:", err));
      } else if (index === 1) {
        router.push({ name: "OldWall232" }).catch((err) => console.error("Routing error:", err));
      } else if (index === 2) {
        router.push({ name: "OldWall38" }).catch((err) => console.error("Routing error:", err));
      } else if (index === 3) {
        router.push({ name: "OldWall40" }).catch((err) => console.error("Routing error:", err));
      }
    };

    const isMobile = ref(window.innerWidth < 1024);

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth < 1024;
    };

    window.addEventListener('resize', updateIsMobile);

    onMounted(async () => {
      showLoader.value = true;
      logo.value = await getToolBarLogo();
      setTimeout(() => {
        showLoader.value = false;
      }, 800);
      updateIsMobile();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateIsMobile);
    });

    return {
      showLoader,
      logo,
      goBack,
      cards,
      hoverCard,
      selectCard,
      isMobile,
      toobarTitle
    };
  }
};
</script>

<style scoped>
.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70vh; /* Adjust as needed for vertical centering */
}

.toolbar-icon {
  height: 30px;
  align-self: center;
  margin-right: 20px;
}

.elegant-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s, filter 0.3s;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
}

.elegant-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.active-card {
  filter: brightness(1);
}

.dim-card {
  filter: brightness(0.5);
}

.card-image {
  width: 100%;
  height: 80%;
  object-fit: cover;
  transition: transform 0.3s;
}

.card-image-hover {
  transform: scale(1.1);
}

.card-bottom {
  background-color: black;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-title {
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}
</style>
