<template >
    <div class="user-bookings">
     <h1>UserBookings</h1>
    </div>
   </template>
   
   <script>
   import { defineComponent, onMounted } from 'vue';
   
   export default defineComponent({
     name: 'UserBookings',
     components: {
     },
     setup() {
       onMounted(async () => {
         
       });
     },
   });
   </script>

   <style scoped>
   .user-bookings {
     height: 100vh;
     display: flex;
     flex-direction: column;
     
   }
   </style>
   
   