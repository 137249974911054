import { Device } from "@capacitor/device";
const images = {
  artinvest: {
    login: {
      web: require("@/assets/Branding/Artinvest/artivest-login-background_web.jpg"),
      mobile: require("@/assets/Branding/Artinvest/artivest-login-background_mobile.jpg"),
    },
    loader: {
      loaderLogo: require('@/assets/Branding/Artinvest/artinvest-loader.png'),
    },
  },
};

export const getLoginBackgroundImage = async () => {
  const info = await Device.getInfo();
  const isWeb = info.platform === "web";
  let customer = "artinvest";
  if (customer === "artinvest") {
    let loginImage = images[customer].login;
    return isWeb ? loginImage.web : loginImage.mobile;
  } else {
    return null;
  }
};

export const getLoaderLogo = () => {
  let customer = "artinvest";
  let loaderImage = images[customer].loader.loaderLogo;
  return loaderImage
};

export const getToolBarLogo = () => {
  let customer = "artinvest";
  let toolbarlogo = images[customer].loader.loaderLogo;
  return toolbarlogo
}

export const getCustomerName = () => { 
  let customer = "Art-Invest";
  return customer;
}

export const getBottomBarColor = () => {

  let customer = "artinvest";
  if (customer === "artinvest") {
    return "#50C878";
  } else {
    return "#FFD700";
  }
}

export const getPrimaryColor = () =>
{
  let customer = "artinvest";
  if (customer === "artinvest") {
    return "#50C878";
  } else {
    return "white";
  }
}
