<template>
    <div>
      <div v-if="activeCard === 1">
        <v-card class="history-card">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2021/02/1905.jpg" class="history-image" />
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="history-title">1188</h2>
              <h3 class="history-subtitle">GROSSER BURSTAH ERSTE HANDELSSTRASSE</h3>
              <p class="history-text">Um 1188 war der große Burstah, dank einer Veranlassung von Wirad von Boizenburg, eine der Hamburger Hauptstraßen. Zum Ende des 19. und zu Beginn des 20. Jahrhunderts war der Große Burstah dann eine der besten Hamburger Einkaufsadressen. Hermann Tietz gründete am 1. März 1897 in den Häusern 12 und 14 sein Warenhaus.</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="history-card">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2021/02/1585.jpg" class="history-image" />
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="history-title">1558</h2>
              <h3 class="history-subtitle">GRÜNDUNG DER BÖRSE</h3>
              <p class="history-text">Die Hamburger Börse wurde im Jahre 1558 gegründet, als der Kaufmannschaft vom Rat der Stadt erlaubt wurde, den gepflasterten Platz gegenüber dem Rathaus an der Trostbrücke als täglichen Versammlungsort zu nutzen. An diesem Platz entstand von 1577 bis 1583 ein erstes Börsengebäude – im Stile der Renaissance. Bis 1794 wurde das Gebäude mehrmals ausgebaut.</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="history-card">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2021/02/1841.jpg" class="history-image" />
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="history-title">1841</h2>
              <h3 class="history-subtitle">NEUES BÖRSENGEBÄUDE</h3>
              <p class="history-text">Der ständig wachsende Börsenbetrieb brauchte dringend eine Erweiterung des vorhandenen Gebäudes. Schon Anfang des 19. Jahrhunderts spielte man mit dem Gedanken einen Neubau zu wagen. Doch erst nach jahrelangen Beratungen über Finanzierung, Standort und architektonische Ausgestaltung konnte 1837 am Adolphsplatz mit dem Bau begonnen werden. Die feierliche Einweihung der „Neuen Börse“ fand schließlich am 2. Dezember 1841 statt. Zwei Tage später zog der Börsenbetrieb um.</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="history-card">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2021/02/1842.jpg" class="history-image" />
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="history-title">1842</h2>
              <h3 class="history-subtitle">DER GROSSE BRAND</h3>
              <p class="history-text">Der große Brand verwüstete 1842 über mehrere Tage lang nahezu die gesamte Hamburger Innenstadt. Tausende wurden obdachlos, dutzende Menschen ließen ihr Leben in den Flammen. Als der Brand schließlich gelöscht war, begann in den Folgejahren postwendend der Wiederaufbau. Auch am Alten Wall.</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="history-card">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2021/02/1865.jpg" class="history-image" />
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="history-title">1856</h2>
              <h3 class="history-subtitle">GRÜNDUNG VEREINSBANK (ERSTES DOMIZIL AM ALTEN WALL 64)</h3>
              <p class="history-text">Nachdem in Hamburg über die Gründung einer aktienbasierten Bank nachgedacht wurde und die Stadt zu lange verhandelte, gründete eine Gruppe Kaufleute 1856 nach Londoner Vorbild die Vereinsbank. Die Gruppe von Kaufleuten bestand aus Hamburger Bankern, die hauptsächlich mit England handelten. Das Gründungskapital betrug 20 Millionen Mark Banco, wovon 5 Mio. für die Gründungsmitglieder reserviert blieben. Die ausgegebenen Aktien zu 200 Mark Banco wurden bei der Subskription 29-mal überzeichnet. Am 11. August 1856 wurde die Gesellschaft beim Handelsregister eingetragen und die ersten vorläufigen Geschäftsräume an der Adresse Alter Wall 62 bezogen. Die geplante Nordbank wurde ironischer Weise exakt zwei Tage später gegründet.</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="history-card">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2021/02/1892.jpg" class="history-image" />
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="history-title">1892</h2>
              <h3 class="history-subtitle">VEREINHEITLICHUNG DER BÖRSE ZUM GEBÄUDEKOMPLEX</h3>
              <p class="history-text">Die Börse wurde mit dem entstehenden Rathaus durch zwei Flügelbauten zu einem geschlossenen Baukomplex verbunden. Seitliche Tordurchfahrten ermöglichten den Zugang zum gemeinsam gebildeten „Ehrenhof“. Stilistische und repräsentative Gründe machten nun eine Anpassung der rückwärtigen Börsenfassade an den Rathausneubau erforderlich. Daher wurde 1892-1894 eine kräftig gegliederte Sandsteinfassade vorgeblendet. Statt der schlichten Pilaster des spätklassizistischen Baus betonten nun repräsentative Säulen die fünfachsige Gliederung des Eingangsportals.</p>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div v-if="activeCard === 2">
        <v-card class="history-card">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2021/02/1905.jpg" class="history-image" />
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="history-title">1905</h2>
              <h3 class="history-subtitle">EINZELHANDEL AM ALTEN WALL</h3>
              <p class="history-text">Um die Wende zum 20. Jahrhundert siedelte sich am Alten Wall Einzelhandel an. Der Alte Wall war zu dieser Zeit – wie der Jungfernstieg – ein Einkaufsboulevard, auf dem sich das städtische Leben abspielte. In Läden machten die Hamburger ihre Besorgungen und trafen sich in einem der Cafés zu Tee und Kaffee. Börse und Rathaus in direkter Nachbarschaft machten den Alten Wall zu einer belebten Handelslage in der Innenstadt.</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="history-card">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2021/02/1908.jpg" class="history-image" />
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="history-title">1908</h2>
              <h3 class="history-subtitle">KONTORHAUS ALTER WALL 12</h3>
              <p class="history-text">Das Kontorhaus am Alten Wall 12 wurde 1908/1909 von den Architekten Emil Schaudt und Emil Janda erbaut. Zu dieser Zeit galt es als eines der kostspieligsten Gebäude Hamburgs. Eine Besonderheit stellen die manieristischen Architekturdetails wie die langgestreckten Konsolen des Hauptportals dar. Die Fassade ist dreigeteilt und wird von den jeweils hervorstehenden Konsolen beherrscht.</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="history-card">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2021/02/1910.jpg" class="history-image" />
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="history-title">1910</h2>
              <h3 class="history-subtitle">ABLÖSUNG GROSSER BURSTAH DURCH DIE MÖNCKEBERGSTRASSE</h3>
              <p class="history-text">Nach dem Großen Burstah etablierte sich, ab ihrer Freigabe für den Verkehr am 26. Oktober 1909, die neu geschaffene Mönckebergstraße, benannt nach Johann Georg Mönckeberg, als erste Adresse für Einkäufe in Hamburg. Zusammen mit der Spitalerstraße ist sie auch heute noch, mit ca. 10.000 Passanten pro Stunde, die meist frequentierte Straße Hamburgs.</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="history-card">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2021/02/1912.jpg" class="history-image" />
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="history-title">1912</h2>
              <h3 class="history-subtitle">AUSBAU DER BÖRSE</h3>
              <p class="history-text">Wegen Hochbahn-Planungen wurde 1907 der alte Ostflügel niedergelegt. In den Jahren 1909 bis 1912 entstand jedoch großzügiger Ersatz. Die geschaffenen Büroräume waren auch vom Rathaus her zugänglich. Der neue Börsensaal mit seiner ursprünglich farbigen Deckengestaltung des Hamburger Malers Otto Fischer-Trachau nahm die Getreide-, Versicherungs- und Schifffahrtsbörse auf. Die unterschiedliche Höhenentwicklung zwischen dem zweigeschossigen Börsengebäude und dem fünfgeschossigen Ostflügel wurde durch das Einfügen eines Eckturms geschickt vermittelt. Außerdem erfuhr die Börse durch ihn eine wirkungsvolle Betonung ihrer Eigenständigkeit gegenüber dem nahen Rathaus. In drei Bauperioden war es damit gelungen, der Börse im Äußeren und Inneren ein einheitlich wirkendes Erscheinungsbild zu geben und sie schließlich mit dem Rathaus zum bis heute bestimmenden Baukomplex der Hamburger Innenstadt harmonisch zu verbinden.</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="history-card">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2021/02/1945.jpg" class="history-image" />
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="history-title">1945</h2>
              <h3 class="history-subtitle">ALTER WALL 2: REICHSBANKLEITSTELLE DER BRITISCHEN ZONE</h3>
              <p class="history-text">Aufgrund des Krieges und der Verschiebungen durch die Besatzungsmächte wurde die Reichsbankleitstelle von Berlin nach Hamburg verlegt. Dies geschah im November 1945, da die britische Führung es für nicht förderlich befand, den Zins- und Zahlungsverkehr der Reichsbank komplett einzustellen. Das Gebäude der Reichsbank war das jetzige Gebäude Alter Wall 2.</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="history-card">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2021/02/1949.jpg" class="history-image" />
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="history-title">1949</h2>
              <h3 class="history-subtitle">WIEDERAUFBAU ALTER WALL NACH DEM ZWEITEN WELTKRIEG</h3>
              <p class="history-text">Während des zweiten Weltkrieges erlitt das Börsengebäude schwere Schäden. Der Mittelbau und der Westflügel am Alten Wall wurden weitgehend zerstört, nur der östliche Börsensaal blieb damals nutzbar. 1949 begann die Wiederherstellung der historischen Architektur. Seit 1952 steht die Börse unter Denkmalschutz. Durch Renovierungen im Innern wurde das Gebäude den Erfordernissen eines modernen Börsen- und Bürobetriebs angepasst. Im Jahre 1957 war der Wiederaufbau der Börse weitgehend abgeschlossen.</p>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      activeCard: {
        type: Number,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .history-card {
    margin: 20px auto;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: white;
  }
  
  .history-image {
    width: 100%;
    border-radius: 10px;
  }
  
  .history-title {
    font-size: 2em;
    font-weight: bold;
    margin-top: 0;
  }
  
  .history-subtitle {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .history-text {
    font-size: 1em;
    color: #333;
  }
  </style>
  