<template>
  <div>
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-4">
      <v-icon style="margin-left: 10px" size="40" @click="goBack"
        >mdi-arrow-left-bold-circle</v-icon
      >
      <v-toolbar-title
        style="font-family: 'Material Design Icons'; font-size: 25px !important"
      >
        {{ "Ö P N V" }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>

    <transition name="fade">
      <div v-if="!showLoader" class="iframe-container">
        <iframe
          class="iframe-content"
          src="https://alter-wall.veomo.com/"
          frameborder="0"
        ></iframe>
      </div>
    </transition>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from "vue";
import Loader from "@/components/Loader.vue";
import { getToolBarLogo } from "@/assets/Branding/branding.js";
import { useRouter } from "vue-router";

export default {
  name: "VeomoView",
  components: {
    Loader,
  },
  setup() {
    const showLoader = ref(true);
    const logo = ref("");
    const router = useRouter();

    const changeBackgroundColor = (color) => {
      const appWrap = document.querySelector(".v-application__wrap");
      if (appWrap) {
        appWrap.style.backgroundColor = color;
      }
    };

    const goBack = () => {
      router.go(-1);
    };

    onMounted(async () => {
      showLoader.value = true;
      logo.value = await getToolBarLogo();
      setTimeout(() => {
        showLoader.value = false;
      }, 2000);

      changeBackgroundColor("#E2E2E2");
    });

    onBeforeUnmount(() => {
      changeBackgroundColor("#FFFFFF"); // or the original color of your application
    });

    return {
      showLoader,
      logo,
      goBack,
    };
  },
};
</script>

<style scoped>
.toolbar-icon {
  height: 30px;
  align-self: center;
  margin-right: 20px;
}

.iframe-container {
  width: 100%;
  height: calc(100vh - 64px); /* Adjust height as needed */
  background-color: white; /* Ensure the background matches your app */
  border: none;
  margin-top: -10px;
}

.iframe-content {
  width: 100%;
  height: 100%;
  border: none;
}

/* Transition for iframe appearance */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
