<template>
  <v-dialog v-model="isVisible" fullscreen persistent hide-overlay>
    <div class="loader-container">
      <div class="image-loader">
        <img :src="logo" contain />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { getLoaderLogo } from "@/assets/Branding/branding.js";

export default {
  name: "Loader",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const isVisible = ref(props.loading);
    const logo = ref("");

    onMounted(() => {
      const loaderLogo = getLoaderLogo();
      if (loaderLogo) {
        logo.value = loaderLogo;
      }
    });

    watch(
      () => props.loading,
      (newVal) => {
        isVisible.value = newVal;
      }
    );

    return { isVisible, logo };
  },
};
</script>

<style scoped>
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Use vh for full viewport height */
  background-color: rgba(255, 255, 255, 0.1); /* Reduced opacity */
  z-index: 10; /* Ensure it's above other content */
}

.loader-logo {
  width: auto; /* Auto width to maintain aspect ratio */
  height: 10vh; /* Height relative to the viewport */
  max-height: 100px; /* Max height to ensure it's not too big */
}

.image-loader img {
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader div:nth-child(2) {
  animation-delay: -0.3s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
