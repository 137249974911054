<template>
  <div ref="bottomNavRef" class="bottom-nav" v-show="showBottomNav">
    <!-- Home Button -->
    <button
      class="nav-btn"
      :class="{ 'nav-btn--active': modelValue === 'home' }"
      @click="setActiveTab('home')"
      :style="{ color: modelValue === 'home' ? bottomBarColor : '' }"
    >
      <div class="icon-wrapper">
        <i class="mdi mdi-home"></i>
      </div>
      <span class="nav-label">Home</span>
    </button>

    <!-- Menu Button -->
    <button
      class="nav-btn"
      :class="{ 'nav-btn--active': modelValue === 'menu' }"
      @click="toggleDrawer"
      :style="{ color: modelValue === 'menu' ? bottomBarColor : '' }"
    >
      <div class="icon-wrapper">
        <i class="mdi mdi-menu"></i>
      </div>
      <span class="nav-label">Menu</span>
    </button>
  </div>

  <!-- Modal Dialog -->
  <div v-if="drawer" class="modal-overlay" @click.self="toggleDrawer">
    <div class="modal-dialog">
      <button class="close-btn" @click="toggleDrawer">
        <i class="mdi mdi-close"></i>
      </button>
      <ul class="nav-list">
        <li @click="navigate('room-booking')">
          <i class="mdi mdi-calendar-clock"></i> Raumbuchung und -steuerung
        </li>
        <!-- <li @click="navigate('digitalization-features')">
          <i class="mdi mdi-chart-bubble"></i> Digitalization Features
        </li> -->
        <!-- <li @click="navigate('art-invest-content')">
          <i class="mdi mdi-city"></i> Art-Invest Content
        </li> -->
        <!-- <li @click="navigate('project-details')">
          <i class="mdi mdi-file-multiple"></i> Project Details
        </li> -->
        <li @click="navigate('building-navigation')">
          <i class="mdi mdi-map-marker"></i> Gebäudenavigation
        </li>
        <!-- <li @click="navigate('live-webcams-weather')">
          <i class="mdi mdi-camera"></i> Live-Webcams
        </li> -->
        <li @click="navigate('access-control')">
          <i class="mdi mdi-key"></i> Zutrittskontrollsysteme
        </li>
        <li @click="navigate('general-utilities')">
          <i class="mdi mdi-wrench"></i> Allgemeine Dienstprogramme
        </li>
        <!-- <li @click="navigate('restaurant-menus')">
          <i class="mdi mdi-silverware-fork-knife"></i> Restaurant Menus
        </li> -->
        <li @click="navigate('social-media-communication')">
          <i class="mdi mdi-share-variant"></i> Soziale Medien und Kommunikation
        </li>
        <li @click="navigate('user-profile-settings')">
          <i class="mdi mdi-account"></i> Benutzerprofil
        </li>
        <li @click="logout" class="logout-btn">
          <i class="mdi mdi-logout"></i> Ausloggen
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getBottomBarColor } from "@/assets/Branding/branding.js";

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    tabs: {
      type: Array,
      required: true,
    },
    showBottomNav: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const currentTab = ref(props.modelValue);
    const drawer = ref(false);
    const bottomBarColor = ref('');
    const router = useRouter();
    const route = useRoute();
    const bottomNavRef = ref(null);

    const setActiveTab = (tabValue) => {
      if (tabValue === 'home') {
        router.push({ name: 'HomeView' }).catch(err => console.error("Routing error:", err));
      }
      currentTab.value = tabValue;
      emit('update:modelValue', tabValue);
    };

    const toggleDrawer = () => {
      drawer.value = !drawer.value;
    };

    const navigate = (destination) => {
      router.push({ name: `${destination}View` });
      drawer.value = false;
    };

    const logout = async () => {
      const { doLogout } = await import('@/controllers/BackboneAPI.js');
      await doLogout();
      drawer.value = false;
      window.location.href = '/login';
    };

    onMounted(() => {
      bottomBarColor.value = getBottomBarColor();
      if (bottomNavRef.value) {
        document.documentElement.style.setProperty("--bottom-nav-height", `${bottomNavRef.value.clientHeight}px`);
      }
      if (route.path === '/' || route.path.startsWith('/home')) {
        currentTab.value = 'home';
        emit('update:modelValue', 'home');
      }
    });

    // Watch the route and update the currentTab when it changes
    watch(route, (newRoute) => {
      if (newRoute.path === '/' || newRoute.path.startsWith('/home')) {
        currentTab.value = 'home';
        emit('update:modelValue', 'home');
      }
    });

    return {
      currentTab,
      drawer,
      bottomBarColor,
      bottomNavRef,
      setActiveTab,
      toggleDrawer,
      navigate,
      logout,
    };
  },
};
</script>

<style scoped>
.bottom-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
  width: 100%; /* Adjust to full width */
  max-width: 400px; /* Ensure it doesn't get too wide */
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.nav-btn {
  background: transparent;
  border: none;
  color: inherit;
  font-size: 16px;
  padding: 8px 12px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
}

.nav-btn--active,
.nav-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  color: #4CAF50; /* Active color */
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  margin-right: -11px;
}

.nav-label {
  font-size: 12px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-dialog {
  background: white;
  padding: 20px;
  padding-top: 40px; /* Added padding to avoid overlapping */
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  max-width: 90%;
  width: 400px;
  max-height: 80%;
  overflow-y: auto;
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: relative;
  transform: translateY(-20px);
  opacity: 0;
  animation: slideIn 0.3s forwards;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: red;
}

.nav-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-list li {
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  font-size: 16px;
  color: #333;
}

.nav-list li:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transform: translateX(5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logout-btn {
  background-color: #e53935;
  color: white;
  padding: 15px 20px;
}

.mdi {
  margin-right: 10px;
}

@media (max-width: 600px) {
  .modal-dialog {
    width: 90%;
    max-width: none;
    max-height: 60%; /* Reduced height for mobile screens */
  }
}

/* Keyframes for animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


</style>
