<template>
    <div class="artinvest-approach">
      <Loader :loading="showLoader" />
      <v-toolbar color="#222222" flat dense class="mb-2" style="cursor: pointer">
        <v-icon color="#077a2d" class="ml-2" size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
        <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
          {{ "ArtInvest Erklärung" }}
        </v-toolbar-title>
      </v-toolbar>
      <v-container class="presentation-container" v-if="!showPresentation">
        <div class="blur-background">
          <v-btn class="play-button" @click="showPresentation = true">
            Präsentation starten
          </v-btn>
        </div>
      </v-container>
      <v-container v-if="showPresentation" class="presentation-container">
        <iframe 
          :src="presentationUrl" 
          class="presentation-iframe"
          frameborder="0"
          allowfullscreen>
        </iframe>
      </v-container>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import Loader from '@/components/Loader.vue';
  
  export default {
    name: 'ArtInvestExplanation',
    components: {
      Loader,
    },
    setup() {
      const showLoader = ref(true);
      const showPresentation = ref(false);
      const router = useRouter();
      const presentationUrl = ref('https://view.officeapps.live.com/op/embed.aspx?src=https://www.yourserver.com/path/to/ArtInvestApproach.pptx');
  
      const goBack = () => {
        router.go(-1);
      };
  
      onMounted(() => {
        setTimeout(() => {
          showLoader.value = false;
        }, 800); // Simulate loading
      });
  
      return {
        showLoader,
        showPresentation,
        goBack,
        presentationUrl,
      };
    },
  };
  </script>
  
  <style scoped>
  .artinvest-approach {
    display: flex;
    flex-direction: column;
  }
  
  .presentation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px); /* Adjusted for toolbar height */
    position: relative;
  }
  
  .blur-background {
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .play-button {
    font-size: 2em;
    padding: 30px 40px;
    background-color: #077a2d;
    color: white;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .play-button:hover {
    transform: scale(1.1);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  }
  
  .v-btn__content {
    margin-top: -1rem;
  }
  
  .presentation-iframe {
    width: 100%;
    height: 80vh;
    border: none;
  }
  
  @media (max-width: 960px) {
    .presentation-iframe {
      height: 60vh;
    }
  
    .play-button {
      font-size: 1.5em;
      padding: 15px 30px;
    }
  }
  
  @media (max-width: 600px) {
    .presentation-iframe {
      height: 50vh;
    }
  
    .play-button {
      font-size: 1.2em;
      padding: 10px 20px;
    }
  }
  </style>
  