<template>
  <div class="hoxton-brand">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-2" style="cursor: pointer">
      <v-icon color="#077a2d" class="ml-2" size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
      <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
        {{ "Unsere Hotels Standorte" }}
      </v-toolbar-title>
    </v-toolbar>

    <v-container class="px-10 py-5">
      <v-row align="center">
        <v-col cols="9" md="9" lg="10">
          <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Hotel suchen" single-line
            hide-details variant="solo-inverted" density="compact" class="elegant-search" full-width></v-text-field>
        </v-col>
        <v-col cols="3" md="3" lg="2">
          <v-menu :location="'start'" max-height="500px">
            <template v-slot:activator="{ props }">
              <v-btn small color="green" dark v-bind="props" class="filter-button">
                <v-icon left size="small">mdi-filter</v-icon>
                Standorte
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="filterByLocation('All')">
                <v-list-item-title>Alle</v-list-item-title>
              </v-list-item>
              <v-list-item v-for="location in uniqueLocations" :key="location" @click="filterByLocation(location)">
                <v-list-item-title>{{ location }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col class="text-right">
          <v-btn v-for="filter in filters" :key="filter" @click="setFilter(filter)"
            :class="{ 'active-filter': selectedFilter === filter, 'filter-btn': true }">
            {{ filter }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <transition-group name="fade" tag="div" class="d-flex flex-wrap">
          <v-col v-for="hotel in filteredHotels" :key="hotel.location" cols="12" sm="6" md="4" lg="3" class="hotel-card">
            <v-card>
              <v-img :src="hotel.image" height="170px" class="hotel-image"></v-img>
              <v-card-title class="title">{{ hotel.location }}</v-card-title>
              <v-card-subtitle class="subtitle">{{ hotel.address }}</v-card-subtitle>
              <v-card-text>{{ hotel.description }}</v-card-text>
              <v-card-actions>
                <v-btn @click="openModal(hotel.link)" class="hotel-btn">Hotel ansehen</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </transition-group>
      </v-row>
    </v-container>

    <!-- Modal -->
    <v-dialog v-model="showModal" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Hotel Website</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <iframe :src="iframeSrc" width="100%" height="500px"></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { ref, computed } from 'vue';  // Import computed here
import { useRouter } from 'vue-router'; // Import useRouter
import Loader from '@/components/Loader.vue';

export default {
  name: 'HoxtonTheBrand',
  setup() {
    const showLoader = ref(true);
    const search = ref('');
    const selectedFilter = ref('Alle');
    const showModal = ref(false);
    const iframeSrc = ref('');
    const router = useRouter(); // Initialize useRouter

    const filters = ['Alle', 'Europa', 'Amerika'];
    const hotels = ref([
    {
          location: 'Herengracht, Amsterdam',
          address: 'Herengracht 255, 1016 BJ Amsterdam, Netherlands',
          description: 'Ein zweites Zuhause am Herengracht-Kanal, inmitten der charmantesten „9 Straßen“ der Stadt.',
          link: 'https://thehoxton.com/amsterdam/herengracht/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2020/04/Hoxton_AMS_5_3197-1.jpg?quality=70',
          region: 'Europa'
        },
        {
          location: 'Lloyd, Amsterdam',
          address: 'Oostelijke Handelskade 34, 1019 BN Amsterdam',
          description: 'Ein denkmalgeschütztes Gebäude neben einer original holländischen Windmühle im bezaubernden östlichen Hafenviertel von Amsterdam.',
          link: 'https://thehoxton.com/amsterdam/lloyd/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2023/02/studio-lloyd-a-frame.jpg?w=1600&quality=70',
          region: 'Europa'
        },
        {
          location: 'Barcelona',
          address: 'Avinguda Diagonal, 205, 08018 Barcelona, Spain',
          description: 'Mit seinem sonnendurchfluteten Dach, Blick auf die Sagrada Familia und Zimmern in mediterranen Farbtönen.',
          link: 'https://thehoxton.com/poblenou/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2022/01/bcn-roomy-view-1.jpg?quality=70',
          region: 'Europa'
        },
        {
          location: 'Chicago',
          address: '200 N Green St, Chicago, IL 60607, USA',
          description: 'Eingebettet in den Fulton Market haben Sie hier Concept Stores und die besten Restaurants der Stadt direkt vor Ihrer Haustür.',
          link: 'https://thehoxton.com/chicago/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2020/04/THC_Snug1_02_RET-edit.jpg?quality=70',
          region: 'Amerika'
        },
        {
          location: 'Los Angeles, Downtown',
          address: '1060 S Broadway, Los Angeles, CA 90015, USA',
          description: 'Wir sind mitten im Geschehen der DTLA und haben vom Pool auf dem Dach einen Blick auf den Broadway.',
          link: 'https://thehoxton.com/downtown-la/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2020/04/THDTLA_Rooms_NewLamp_June24-1980-x-1365-px.jpg?quality=70',
          region: 'Amerika'
        },
        {
          location: 'New York, Williamsburg',
          address: '97 Wythe Ave, Brooklyn, NY 11249, USA',
          description: 'Ein perfekter Ausgangspunkt, um New York zu erkunden, mit dem Besten von Brooklyn vor der Haustür und einem atemberaubenden Blick auf Manhattan.',
          link: 'https://thehoxton.com/williamsburg/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2020/04/200108_Hoxton_New_York_14828_V2a.jpg?quality=70',
          region: 'Amerika'
        },
        {
          location: 'Portland, Oregon',
          address: '15 NW 4th Ave, Portland, OR 97209, USA',
          description: 'Mitten in der historischen Altstadt von Chinatown, einem Viertel mit einer unglaublichen Vergangenheit und einer aufregenden Zukunft.',
          link: 'https://thehoxton.com/portland/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2020/04/THPDX_17466.jpg?quality=70',
          region: 'Amerika'
        },
        {
          location: 'Berlin',
          address: 'Meinekestraße 18-19, 10719 Berlin, Germany',
          description: 'Unser Hoxton im gehobenen Charlottenburg im Westen Berlins liegt in einem kreativen Viertel voller moderner Kunst und viel Berliner Charakter.',
          link: 'https://thehoxton.com/charlottenburg/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2023/06/3.-TheHox_CHAR_Day3_430_Wide_2_018_V1a.jpg?quality=70',
          region: 'Europa'
        },
        {
          location: 'Brussels',
          address: 'The Hoxton Brussels, Square Victoria Régina 1, 1210 Brussels, Belgium',
          description: 'Das Hoxton, Brüssel befindet sich in einem brutalistischen Hochhaus und der ehemaligen Europazentrale von IBM.',
          link: 'https://thehoxton.com/brussels/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2022/07/hox-brussels-rooms-.jpg?quality=70',
          region: 'Europa'
        },
        {
          location: 'Holborn, London',
          address: '199-206 High Holborn, Holborn, London WC1V 7BD',
          description: 'Von unserem Hotel in Holborn aus sind Sie überall in der Nähe und im Herzen der Londoner Innenstadt.',
          link: 'https://thehoxton.com/london/holborn/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2020/04/HOXTON_270717_0983.jpg?quality=70',
          region: 'Europa'
        },
        {
          location: 'Shepherds Bush, London',
          address: '65 Shepherd’s Bush Green, London, W12 8QE',
          description: 'Heimat unabhängiger Geschäfte, lebhafter Restaurants und echter Londoner Pubs.',
          link: 'https://thehoxton.com/london/shepherds-bush/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2022/07/shebu-little-touches-1.jpg?quality=70',
          region: 'Europa'
        },
        {
          location: 'Shoreditch, London',
          address: '81 Great Eastern St, Hackney, London EC2A 3HU',
          description: 'Unser ursprüngliches Hotel in Hoxton bringt Sie mitten in das ikonische East London.',
          link: 'https://thehoxton.com/london/shoreditch/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2020/04/HOXTON_270717_0210-1.jpg?quality=70',
          region: 'Europa'
        },
        {
          location: 'Southwark, London',
          address: '40 Blackfriars Rd, South Bank, London SE1 8NY',
          description: 'Nur einen Steinwurf von der Themse entfernt, in einem der kulturell reichsten Viertel Londons.',
          link: 'https://thehoxton.com/london/southwark/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2020/02/Hoxton-Southwark-Biggy-Hero-2084_RET_72dpi-1.jpg?quality=70',
          region: 'Europa'
        },
        {
          location: 'Paris',
          address: '30-32 Rue du Sentier, 75002 Paris, France',
          description: 'Eine prächtige Residenz aus dem 17. Jahrhundert mit originalen Treppen und Innenhöfen im 2. Arrondissement von Paris.',
          link: 'https://thehoxton.com/paris/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2020/04/2.jpg?quality=70',
          region: 'Europa'
        },
        {
          location: 'Rome',
          address: 'Largo Benedetto Marcello, 220, 00198 Roma, Italy',
          description: 'Unsere 10. Box in einer grünen Ecke Roms, nur einen Steinwurf von der Villa Borghese entfernt.',
          link: 'https://thehoxton.com/rome/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2020/06/HERO_Hoxton_Rome_Cosy_136.jpg?w=1600&quality=70',
          region: 'Europa'
        },
        {
          location: 'Vienna',
          address: 'Rudolf-Sallinger-Platz 1, 1030 Vienna, Austria',
          description: 'Das Hoxton Vienna befindet sich in einem Gebäude aus den 1950er-Jahren in der Landstraße und verfügt über einen einzigartigen, historischen Charakter.',
          link: 'https://thehoxton.com/vienna/',
          image: 'https://thehoxton.com/wp-content/uploads/sites/5/2023/10/hox-vienna-room-gallery-2.jpg?quality=70',
          region: 'Europa'
        },
        // {
        //   location: 'Ediburgh, Scotland',
        //   address: 'New York',
        //   description: '',
        //   link: 'https://thehoxton.com/chicago/',
        //   image: 'path_to_image_new_york.jpg',
        //   region: 'Europa'
        // },
        // {
        //   location: 'Florance',
        //   address: 'New York',
        //   description: 'Description for New York, Williamsburg.',
        //   link: 'https://example.com',
        //   image: 'path_to_image_new_york.jpg',
        //   region: 'Europa'
        // },
    ]);
    const goBack = () => {
      router.go(-1);
    };

    const setFilter = (filter) => {
      selectedFilter.value = filter;
    };

    const openModal = (link) => {
      iframeSrc.value = link;
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
      iframeSrc.value = '';
    };

    const filterByLocation = (location) => {
      search.value = location;
    };

    const filteredHotels = computed(() => {
      let filtered = hotels.value;
      if (selectedFilter.value !== 'Alle') {
        filtered = filtered.filter(hotel => hotel.region === selectedFilter.value);
      }
      if (search.value) {
        filtered = filtered.filter(hotel =>
          hotel.location.toLowerCase().includes(search.value.toLowerCase())
        );
      }
      return filtered;
    });

    const uniqueLocations = computed(() => {
      const locations = hotels.value.map(hotel => hotel.location);
      return [...new Set(locations)];
    });

    return {
      showLoader,
      search,
      filters,
      selectedFilter,
      showModal,
      iframeSrc,
      hotels,
      goBack,
      setFilter,
      openModal,
      closeModal,
      filterByLocation,
      filteredHotels,
      uniqueLocations
    };
  }
};
</script>

<style scoped>
h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.text-right {
  text-align: right;
}

.v-card {
  margin: 0.4rem;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.v-card-title {
  font-size: 1rem;
  font-weight: bold;
  flex-grow: 0;
}

.v-card-subtitle {
  font-size: 0.8rem;
  color: grey;
  flex-grow: 0;
  white-space: normal;
  /* Allows text to wrap */
  line-height: 1.2em;
  /* Line height to make address text more readable */
  max-height: 2.4em;
  /* Ensures two lines of text are shown */
  overflow: hidden;
  /* Hides any overflow text */
  text-overflow: ellipsis;
  /* Adds ellipsis for overflow text */
}

.v-card-text {
  font-size: 0.9rem;
  flex-grow: 1;
  /* Ensures this section takes the remaining space */
}

.v-card-actions {
  flex-grow: 0;
}

.hotel-image {
  width: 100%;
  object-fit: cover;
}

.hotel-btn {
  background-color: #077a2d;
  color: white;
}

.filter-btn {
  margin-left: 10px;
  background-color: #f5f5f5;
}

.active-filter {
  background-color: #077a2d;
  color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
  transform: translateY(30px);
}

.hotel-card {
  flex-grow: 1;
  max-width: 25%;
}

@media (max-width: 1200px) {
  .hotel-card {
    max-width: 33.3333%;
  }
}

@media (max-width: 960px) {
  .hotel-card {
    max-width: 50%;
  }
}

@media (max-width: 600px) {
  .hotel-card {
    max-width: 100%;
  }

  .hotel-image {
    height: auto;
    /* Makes the image responsive on mobile */
  }
}
</style>
