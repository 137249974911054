<template>
  <div class="alter-wall-40">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-2" style="cursor: pointer">
      <v-icon color="#077a2d" class="ml-2" size="40" @click="goBack">
        mdi-arrow-left-bold-circle
      </v-icon>
      <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
        {{ "Alter Wall 40" }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <!-- Existing Card -->
      <v-card class="alter-wall-card" v-if="isActive">
        <v-row>
          <v-col cols="12" md="6">
            <img :src="images[0]" class="header-image" />
          </v-col>
          <v-col cols="12" md="6">
            <img :src="images[1]" class="header-image" />
          </v-col>
        </v-row>
        <v-card-title class="card-title">{{ title }}</v-card-title>
        <v-card-text class="card-text">
          <p>{{ description }}</p>
          <p v-for="paragraph in history" :key="paragraph">{{ paragraph }}</p>
          <v-row class="fact-row">
            <v-col cols="12">
              <div class="facts">
                <v-row v-for="fact in facts" :key="fact.label" class="fact-row">
                  <v-col cols="6" class="fact-label">{{ fact.label }}</v-col>
                  <v-col cols="6" class="fact-value">{{ fact.value }}</v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- New Content Card -->
      <v-card class="new-content-card" v-if="isActive">
        <v-card-title class="card-title">Neue Lebenswelten am Alten Wall</v-card-title>
        <v-card-text class="card-text">
          <v-row>
            <v-col cols="12" md="6">
              <div class="text-content">
                <p>Zwischen Rathaus, Börse und Handelskammer liegt die Prachtstraße Alter Wall. Ihr Name 
                  erinnert noch heute an ihre wichtige Funktion als Wehranlage Hamburgs. Doch hinter den 
                  beeindruckenden historischen und modernen Fassaden hat sich der Alte Wall neu erfunden – mit 
                  exklusiven Geschäften und Restaurants, mit internationalen Ausstellungen im Bucerius Kunst 
                  Forum und mit neuen Arbeitswelten. Die ganze Straße überzeugt nicht nur durch Zentralität, 
                  Flexibilität und Modernität, sondern eben auch durch das lebendige Umfeld, das jeden Tag eine 
                  Vielfalt an Erlebnismöglichkeiten bietet.
                </p>
                <p>Eine Adresse mit Geschichte, Gegenwart und Zukunft. Wie der Alte Wall 40, wo jetzt ein neues 
                  Stück Hamburg gestaltet wird – ein lebendiges wie vielfältiges Ensemble aus Büro-, Einzelhandels- und 
                  Wohnflächen sowie dem Lifestyle-Hotel The Hoxton. Im Zusammenspiel der Funktionen entsteht etwas, das 
                  die Qualitäten von Städten der Zukunft ausmacht. Es wächst ein neuer, attraktiver Erlebnis- und 
                  Lebensmittelpunkt heran, der dazu beiträgt, die Aufenthalts- und Lebensqualität für die Menschen in 
                  der Hamburger Innenstadt weiter zu steigern.
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/850_3210.jpg" class="fact-image" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <div class="text-content">
                <h3>Alter Wall – neues Zentrum für Spitzenqualität</h3> <br>
                <p>Prägendes Vorbild für Modernisierung und Revitalisierung</p>
                <p>Der Alte Wall hat den Sprung in die Zukunft geschafft und zeigt, wie es gelingt, Historie und 
                  Moderne zu vereinen sowie Bestand mit moderner Nutzung attraktiv und spannend zu gestalten. Mit 
                  behutsamen, aber wirksamen Maßnahmen wie Nachverdichtung und Bestandsmodernisierung wird die 
                  Straße wieder in ein lebendiges Quartier verwandelt.
                </p>
                <p>Herausragendes Beispiel ist das neue Ensemble Alter Wall 40. Hier wird deutlich werden, wie eine 
                  gekonnte Nutzungsmischung nicht nur dem Objekt selbst, sondern dem gesamten Straßenzug frischen 
                  Aufwind verschafft. Der geplante Mix aus Lifestyle-Hotel, Premium-Offices, Wohnen in Top-Lage sowie 
                  erlesenen Geschäften und exquisiter Gastronomie wird den traditionsreichen Boulevard in einen Ort 
                  mit exklusivem Flair verwandeln. Hier erwartet die künftigen Mieter und Bewohner eine neue Lebenswelt 
                  für alle Sinne, jede Tageszeit – und höchste Ansprüche.
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/AdobeStock_622628724.jpg" class="fact-image" />
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="12" md="6">
              <div class="text-content">
                <h3>Top-Lage, First Class Umfeld</h3><br>
                <p>Zentraler Boulevard mitten in der Hamburger Innenstadt</p>
                <p>Mitten im Nikolai-Quartier, der Verbindung zwischen westlicher und östlicher Innenstadt, zwischen 
                  Alsterfleet, Rathaus und der Luxusmeile Neuer Wall, befindet sich der Alte Wall 40 – zentraler 
                  geht’s wirklich nicht! Die urbane City-Lage zählt mit ihren vielseitigen Shopping- und Gastronomieangeboten 
                  in unmittelbarer Umgebung zu den attraktivsten Standorten Hamburgs. Busse, Bahnen und weitere 
                  Mobilitätsangebote sorgen für beste An- und Verbindungen nach Nah und Fern. Auch Kultur und Kulinarik, 
                  diverse berühmte Sehenswürdigkeiten, Szene- und Shopping-Spots – alles fußläufig innerhalb kürzester 
                  Zeit erreichbar. Darüber hinaus ist der Alte Wall als Flanierboulevard die attraktivste Verbindung 
                  zwischen Rathausmarkt und Rödingsmarkt bis hin zur HafenCity.
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/Umfeld-1_Fahrzeiten@2x.jpg" class="fact-image" />
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="12" md="6">
              <div class="text-content">
                <h3>Aufwertung für höchstes Niveau</h3><br>
                <p>Der Alte Wall und sein Umfeld haben sich mehrfach erneuert und qualitativ immer weiter verbessert. 
                  Damit die Standortqualität stets auf höchstem Level gehalten werden kann, wurde 2014 das BID Nikolai-Quartier 
                  gegründet. BID steht für „Business Improvement District“ und somit für die Weiterentwicklung von städtischen 
                  Geschäftszentren auf Grundlage privater Initiativen. Die rechtlichen Grundlagen hat Hamburg bereits 2005 
                  geschaffen.
                </p>
                <p>Das BID Nikolai-Quartier ist das siebte BID in der Hamburger Innenstadt – und befindet sich zwischen 
                  Rathausplatz und Alsterfleet, Rödingsmarkt, Willy-Brandt-Straße und Nikolaifleet. Mit einem Budget von 
                  rund 9,32 Millionen Euro wurde zwischen 2014 und 2019 ein wichtiger Teil umstrukturiert, instandgesetzt 
                  und hochwertig gestaltet. Die positiven Resultate sind überall zu sehen. Die Wiederbelebung des Alten 
                  Walls stellt einen bedeutenden Bestandteil für die Aufwertung des öffentlichen Raums in Hamburgs 
                  Innenstadt dar.
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/BID-Nikolai@2x-scaled.jpg" class="fact-image" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Floor Plan Card -->
      <v-card class="images-card" v-if="isActive">
        <v-row>
          <v-col cols="12" md="6">
            <div class="image-container">
              <div class="image-title">Stacking Bauteil B</div>
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/09/230914-Stacking-Grundrisse-Bauteil-B-01.svg" class="fact-image" />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="image-container">
              <div class="image-title">Stacking Bauteil D</div>
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/09/230914-Stacking-Grundrisse-Bauteil-D-01.svg" class="fact-image" />
            </div>
          </v-col>
        </v-row>
        <v-card-title class="card-title">Bauteile</v-card-title>
        <v-card-text class="card-text">
          <v-row>
            <v-col cols="12" md="4">
              <div class="image-container">
                <div class="image-title">Erdgeschoss Bauteil B</div>
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/20230825_ART_23_003_Alter-Wall-40_Bauteil-B_EG@2x.jpg" class="fact-image hover-zoom">
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="image-container">
                <div class="image-title">Regelgeschoss Bauteil B – 1.– 5. Obergeschoss</div>
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/20230825_ART_23_003_Alter-Wall-40_Bauteil-B_Regelgeschoss@2x.jpg" class="fact-image hover-zoom">
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="image-container">
                <div class="image-title">Untergeschoss Bauteil B</div>
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2024/01/240123-UG-Bauteil-D-1920x1080.jpg" class="fact-image hover-zoom">
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <div class="image-container">
                <div class="image-title">Erdgeschoss Bauteil D</div>
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/20230825_ART_23_003_Alter-Wall-40_Bauteil-D_EG@2x.jpg" class="fact-image hover-zoom">
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="image-container">
                <div class="image-title">Regelgeschoss Bauteil D</div>
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/20230825_ART_23_003_Alter-Wall-40_Bauteil-D_Regelgeschoss@2x.jpg" class="fact-image hover-zoom">
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="image-container">
                <div class="image-title">Untergeschoss Bauteil D</div>
                <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2024/01/240123-UG-Bauteil-D.jpg" class="fact-image hover-zoom">
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      
       <!-- Sustainability Card -->
       <v-card class="sustainability-card" v-if="isActive">
        <v-card-title class="card-title">Nachhaltigkeit</v-card-title>
        <v-card-text class="card-text">
          <v-row>
            <v-col cols="12" md="6">
              <div class="text-content">
                <p>Ziel der Entwicklung von Alter Wall 40 ist es, langlebige, attraktiv gestaltete Wohn- und Arbeitsräume
                  mit hoher Aufenthaltsqualität für den Nutzer zu schaffen.
                </p>
                <v-btn variant="outlined" class="learn-more-button" href="https://www.alter-wall-hamburg.de/alter-wall-40/nachhaltigkeit/" target="_blank">Lern mehr</v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/byron-johnson-pj1jt4vBGZY-unsplash.jpg" class="fact-image" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  
      <!-- Smart Building Card -->
      <v-card class="smart-building-card" v-if="isActive">
        <v-card-title class="card-title">Intelligentes Gebäude</v-card-title>
        <v-card-text class="card-text">
          <v-row>
            <v-col cols="12" md="6">
              <div class="text-content">
                <p>Häuser sind mehr als bloße Hüllen aus Stahl und Beton. Sie sollen künftig interaktive Begleiter sein, 
                  die die Errungenschaften der Vernetzung weiterführen, eine aktive Symbiose zwischen Mensch und Gebäude 
                  schaffen, kooperativ mit ihren Nutzern zusammenarbeiten und ihnen den Alltag erleichtern – so auch beim 
                  Alten Wall 40.
                </p>
                <v-btn variant="outlined" class="learn-more-button" href="https://www.alter-wall-hamburg.de/alter-wall-40/smart-building/" target="_blank" >Lern mehr</v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/AdobeStock_338127083.jpg" class="fact-image" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  
      <!-- Construction Card -->
      <v-card class="construction-card" v-if="isActive">
        <v-card-title class="card-title">Konstruktion</v-card-title>
        <v-card-text class="card-text">
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2024/04/AlterWall_Z4A4452-min-1920x1281.jpg" class="fact-image hover-zoom" />
              <div class="text-content">Auch künftig werden die Kanäle von Binnenschiffen befahren und der private 
                Anleger am The Hoxton Hotel dient wieder als zentrale Anlaufstelle in der Hamburger Innenstadt.
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2024/04/AlterWall_Z4A4430-min-1920x1281.jpg" class="fact-image hover-zoom" />
              <div class="text-content">Rückbau der ehemaligen Tiefgarage, mit Säge und Meißel wird die Struktur 
                zerteilt und zertrümmert.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <img src="https://www.alter-wall-hamburg.de/wp-content/uploads/2024/06/ArtInvest_-52-1920x1281.jpg" class="fact-image hover-zoom" />
              <div class="text-content">Auch die Fleetenkieker besuchen regelmäßig unsere Baustelle und überzeugen 
                sich vor Ort vom Baufortschritt.
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Loader from '@/components/Loader.vue';

export default {
  name: 'AlterWall40Component',
  components: {
    Loader,
  },
  setup() {
    const showLoader = ref(true);
    const isActive = ref(true); // Added to toggle content visibility
    const router = useRouter();

    const goBack = () => {
      router.go(-1);
    };

    // Simulating a data load or setup
    onMounted(() => {
      setTimeout(() => {
        showLoader.value = false;
      }, 800);
    });

    // Merged data from AlterWall40History child component
    const images = [
      'https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/AA1932.jpg',
      'https://www.alter-wall-hamburg.de/wp-content/uploads/2023/08/AA2719N_blau.jpg'
    ];
    
    const title = 'Alter Wall 40';
    const description = 'Ein Boulevard erfindet sich immer wieder neu. Alter Wall – Zeitzeuge und Wegbereiter der Moderne.';
    const history = [
      'Der Alte Wall hatte es nicht immer ganz leicht. Nachdem Mitte des 16. Jahrhunderts der „neue Wall“ gebaut worden war, verlor der „alte Wall“ seine Schutzfunktion für die Stadt. Doch damals wie heute schaffte der Alte Wall den Wandel. Er wurde Anfang des 17. Jahrhunderts zum Zentrum jüdischen Lebens und erhielt nach dem großen Brand 1842 mit den Kontorhäusern seinen heutigen Charakter. Der Alte Wall ist ein Zeitzeuge, ein Bewahrer von Tradition und ein Wegbereiter der Moderne. Um 1900 schon gab es hier in den Erdgeschossen Geschäfte und Restaurants, während die oberen Geschosse von den Kaufleuten als Kontore genutzt wurden. Von hier war und ist der Weg nicht weit zum Rathaus und zur Börse.',
      'Nach der Zäsur des zweiten Weltkriegs stand der Alte Wall ein zweites Mal vor der Herausforderung, sich neu zu erfinden. Viel Bausubstanz war unwiederbringlich zerstört worden und es bestand Bedarf an Büroflächen. So errichtete die Deutsche Post 1950 und 1980 den Gebäudekomplex Alter Wall 38 – 40 als Postscheckamt. Moderne Entwicklungen im Zahlungsverhalten und in der Datenverarbeitung führten Ende der 1990er Jahre dazu, dass das Gebäude in ein Dorint Fünf-Sterne-Hotel umgebaut wurde. Angekommen in der Gegenwart wird der traditionsreiche Standort mit einem modernen, gemischten Nutzungskonzept wieder zum lebendigen Zentrum der Stadt.'
    ];
    const facts = [
      { label: 'Name', value: 'Alter Wall 40' },
      { label: 'Projektart', value: 'Development und Nachverdichtung' },
      { label: 'Lage', value: 'Alter Wall 40, Hamburg' },
      { label: 'Grundstück', value: '5.341 m²' },
      { label: 'Fläche', value: 'ca. 40.000 m²' },
      { label: 'Stellplätze', value: '70' },
      { label: 'Nutzung', value: 'Hotel, Büro, Wohnen, Gastronomie und Einzelhandel' },
      { label: 'Erworben', value: '2015' },
      { label: 'Fertigstellung', value: 'voraussichtlich 2026' },
      { label: 'Entwurf', value: 'Winking Froh Architekten' },
      { label: 'Ort', value: 'Hamburg' }
    ];

    return {
      showLoader,
      goBack,
      images,
      title,
      description,
      history,
      facts,
      isActive
    };
  }
};
</script>

<style scoped>
  .alter-wall-card,
  .new-content-card,
  .images-card,
  .sustainability-card,
  .smart-building-card,
  .construction-card {
    width: 100%;
    margin: 20px auto;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    background-color: white;
  }
  
  .header-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  
  .card-title {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    white-space: break-spaces;
    margin-top: 20px;
  }
  
  .card-text {
    padding: 20px;
    white-space: break-spaces;
    font-size: 1em;
    color: #333;
  }
  
  .card-text p {
    margin-bottom: 20px;
  }
  
  .facts {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .fact-row {
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
  
  .fact-label {
    font-weight: bold;
    font-size: 1.1em;
  }
  
  .fact-value {
    font-style: italic;
    font-size: 1.1em;
  }
  
  .fact-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .image-container {
    position: relative;
    text-align: center;
  }
  
  .image-title {
    position: relative;
    top: 0;
    left: 0;
    color: black;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .learn-more-button {
    width: auto;
    margin-top: 10px;
    background-color: #077a2d;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .learn-more-button:hover {
    background-color: #077a2d;
  }
  
  .hover-zoom {
    transition: transform 0.3s ease;
  }
  
  .hover-zoom:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .header-image {
      height: 200px;
    }
  
    .fact-label, .fact-value {
      font-size: 1em;
    }
  
    .iframe-content {
      height: 300px;
    }
  }
  </style>
  