<template>
  <div class="building">

  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";



export default defineComponent({
  name: "BuildingView",
  components: {
  },
  setup() {
    onMounted(async () => {});
    return { };
  },
});
</script>

<style scoped>

</style>
