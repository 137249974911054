

// src/configLoader.js
const configs = {
  'art-invest.showroom.pinestack.eu': () => import('../config/config.js'),
  'localhost': () => import('../config/config.js'), // Updated for localhost
  
};

export const loadConfig = async () => {
  const hostname = window.location.hostname;
  console.log('hostname', hostname);
  for (const domain in configs) {
      if (hostname.includes(domain)) {
          const configModule = await configs[domain]();
          return configModule.default; // Make sure to return the default export of the module
      }
  }
  throw new Error('Configuration not found for this domain: ' + hostname);
};
