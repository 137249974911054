import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import vue3videoPlay from "vue3-video-play"; // Importing the component
import "vue3-video-play/dist/style.css"; // Importing CSS

loadFonts()

const app = createApp(App)

app.use(router)
app.use(store)
app.use(vuetify)
app.use(VCalendar, {})
app.use(vue3videoPlay, { lang: 'en' })
app.mount('#app')
