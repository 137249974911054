<template>
  <div>
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-4">
      <v-icon style="margin-left: 10px" size="40" @click="goBack"
        >mdi-arrow-left-bold-circle</v-icon
      >
      <v-toolbar-title
        style="font-family: 'Material Design Icons'; font-size: 25px !important"
      >
        {{ "S h o w r o o m" }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Loader from "@/components/Loader.vue";
import { getToolBarLogo } from "@/assets/Branding/branding.js";
import { useRouter } from "vue-router";

export default {
  name: "ShowroomView",
  components: {
    Loader,
  },
  setup() {
    const showLoader = ref(true);
    const logo = ref("");
    const router = useRouter();

    const goBack = () => {
      router.go(-1);
    };

    onMounted(async () => {
      showLoader.value = true;
      logo.value = await getToolBarLogo();
      setTimeout(() => {
        showLoader.value = false;
      }, 2000);
    });

    return {
      showLoader,
      logo,
      goBack,
    };
  },
};
</script>

<style scoped>
/* Add your scoped styles here */
.toolbar-icon {
  height: 30px;
  align-self: center;
  margin-right: 20px;
}
</style>
