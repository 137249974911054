<template>
  <div class="alterwall-ensemble">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-2" style="cursor: pointer">
      <v-icon color="#077a2d" class="ml-2" size="40" @click="goBack">
        mdi-arrow-left-bold-circle
      </v-icon>
      <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
        {{ "Das Ensemble" }}
      </v-toolbar-title>
    </v-toolbar>

    <v-container>
      <!-- Header Image -->
      <!-- <v-img src="@/assets/Branding/Artinvest/ensemble.png> --> 
      <v-img src="@/assets/Branding/Artinvest/allalterwalls.jpg"  alt="Header Image" class="header-image"></v-img>
      <!-- Section: Alter Wall 2-8 -->
      <v-card class="animated-card">
        <v-card-title>Alter Wall 2-8</v-card-title>
        <v-card-text>
          <p>Das Kontorhaus am Alten Wall 2-8 liegt direkt am Rathausmarkt und ist das Aushängeschild des historischen Ensembles. 
            Die internationale Modemarke UNIQLO hat hier auf vier Etagen ihren Hamburger Flagship-Store im Herbst 2020 eröffnet 
            und bereits viele Fans gewonnen. Zu den Besonderheiten des Stores gehört u.a. das Atrium mit mosaikbestückten Säulen, 
            die ungewöhnliche Wendeltreppe, von der man einen direkten Blick auf das Oktogon im Dach hat. Teile des Treppenhauses 
            wurden außerdem vom Hamburger Künstler und Illustrator Stefan Mosebach gestaltet.
          </p> <br>
          <div class="info-box">
            <div class="info">
              <h3 style="color: #077a2d;">3277 m²</h3>
              <p>BÜROFLÄCHE</p>
            </div>
            <div class="info">
              <h3 style="color: #077a2d;">2485 m²</h3>
              <p>RETAILFLÄCHE (ALTER WALL 2)</p>
            </div>
            <div class="info">
              <h3 style="color: #077a2d;">1392 m²</h3>
              <p>RETAILFLÄCHE (ALTER WALL 8)</p>
            </div>
          </div>
        </v-card-text>
      </v-card> 

      <!-- Section: Office Info Alter Wall 2 -->
      <v-card class="animated-card">
        <v-card-title>Office (Alter Wall 2-8)</v-card-title>
        <v-card-text>
          <p>Geschossfläche ab: 1046 m²</p>
          <p>Etagen: 3. – 5. Obergeschoss</p>
          <p>Besonderheiten: Flexible Büroaufteilung (Zellenbüro, Gruppenbüro etc.), Raumhohe Verglasung zum Innenhof, Lichte Raumhöhe von 3 m.</p>
        </v-card-text>
      </v-card>

      <!-- Section: Retail Info Alter Wall 2 -->
      <v-card class="animated-card">
        <v-card-title>Retail (Alter Wall 2)</v-card-title>
        <v-card-text>
          <p>Geschossfläche ab: 1348 m²</p>
          <p>Etagen: Fleetgeschoss, Erdgeschoss, 1. Obergeschoss, 2. Obergeschoss</p>
          <p>Besonderheiten: Fronten zum Alten Wall, Rathausmarkt und zum Fleet</p>
          <p>Schaufensterfläche: 43,2 m²</p>
          <p>Tür: 11,88 m²</p>
        </v-card-text>
      </v-card>

      <!-- Section: Retail Info Alter Wall 8 -->
      <v-card class="animated-card">
        <v-card-title>Retail (Alter Wall 8)</v-card-title>
        <v-card-text>
          <p>Geschossfläche ab: 369 m²</p>
          <p>Etagen: Erdgeschoss, 1. Obergeschoss, 2. Obergeschoss</p>
          <p>Besonderheiten: Fronten zum Alten Wall und zum Fleet</p>
          <p>Schaufensterfläche: 31,28 m²</p>
          <p>Tür: 4,68 m²</p>
        </v-card-text>
      </v-card>

      <!-- Header Image -->
      <!-- <v-img src="@/assets/Branding/Artinvest/OldWall/ensemble.PNG" /> -->
      <v-img src="@/assets/Branding/Artinvest/alterwall10.png" alt="Header Image" class="header-image"></v-img>
      <!-- Section: Alter Wall 2-8 -->
      <v-card class="animated-card">
        <v-card-title>Alter Wall 10</v-card-title>
        <v-card-text>
          <p>Hinter der einzigartigen neoklassizistischen Fassade von Haus Nr. 10 verbergen sich modernste 
            Nutzflächen für Retail und Office. Für die Retailfläche konnte Art-Invest Real Estate die 
            erfolgreiche Marke Isabella Glutenfreie Pâtisserie als langfristigen Mieter gewinnen. Isabella 
            Glutenfreie Pâtisserie wird ihren zweiten Standort in der Hansestadt auf dem Flanierboulevard 
            eröffnen. Auf 364 m² werden außergewöhnliche Bäckerei- und Pâtisserie-Produkte sowie Kaffeespezialitäten 
            angeboten. Isabella Glutenfreie Pâtisserie eröffnete 2015 den ersten Store in Düsseldorf. Heute ist die 
            Marke deutschlandweit an neun Standorten vertreten. Das Sortiment des Familienunternehmens ist nicht nur 
            komplett glutenfrei, sondern ebenfalls größtenteils vegan, laktose- sowie milch- und zuckerfrei. Isabellas 
            Brote und Brötchen sind zudem alle mit dem Biosiegel versehen. Mit seinem Konzept bedient das Unternehmen 
            aus Düsseldorf nicht nur den steigenden Bedarf an Produkten, die Nahrungsmittelunverträglichkeiten wie 
            Zöliakie oder Laktoseintoleranz berücksichtigen, sondern auch die zunehmenden Trends einer veganen oder 
            zuckerfreien Ernährungsweise. Die Stores zeichnet ein urbanes, zeitgemäßes und nachhaltiges Design aus, 
            das vom holländischen Designer Piet Hein Eek konzipiert und umgesetzt wurde.
          </p> <br>
          <div class="info-box">
            <div class="info">
              <h3 style="color: #077a2d;">2468 m²</h3>
              <p>Bürofläche</p>
            </div>
            <div class="info">
              <h3 style="color: #077a2d;">805 m²</h3>
              <p>Retailfläche</p>
            </div>
          </div>
        </v-card-text>
      </v-card> 

      <!-- Section: Office Info Alter Wall 2 -->
      <v-card class="animated-card">
        <v-card-title>Office</v-card-title>
        <v-card-text>
          <p>Geschossfläche ab: 227 m²</p>
          <p>Etagen: 3. – 6. Obergeschoss</p>
          <p>Besonderheiten: Flexible Büroaufteilung (Zellenbüro, Gruppenbüro etc.),</p>
          <p>Raumhohe Verglasung zum Innenhof, Lichte Raumhöhe von 3 m</p>
        </v-card-text>
      </v-card>

      <!-- Section: Retail Info Alter Wall 2 -->
      <v-card class="animated-card">
        <v-card-title>Retail</v-card-title>
        <v-card-text>
          <p>Geschossfläche ab: 292 m²</p>
          <p>Etagen: Fleetgeschoss, Erdgeschoss, Galeriegeschoss</p>
          <p>Shopanzahl: 2</p>
          <p>Besonderheiten: Fronten zum Alten Wall oder zum Fleet, teilweise Anbindung an die Fleetbrücke</p>
          <p>Schaufensterfläche: 26,29 m²</p>
        </v-card-text>
      </v-card>

      <!-- Header Image -->
      <!-- <v-img src="@/assets/Branding/Artinvest/OldWall/ensemble.PNG" /> -->
      <v-img src="@/assets/Branding/Artinvest/alterwall12.png" alt="Header Image" class="header-image"></v-img>
      <!-- Section: Alter Wall 2-8 -->
      <v-card class="animated-card">
        <v-card-title>Alter Wall 12</v-card-title>
        <v-card-text>
          <p>Als dieses Kontorhaus 1908/09 erbaut wurde, galt es als eines der kostspieligsten in ganz Hamburg. 
            Jetzt erwacht es zu neuem Leben und bildet mit der neu geschaffenen Marion-Gräfin-Dönhoff-Brücke über 
            das Fleet die Verbindung zwischen dem Flanier-Boulevard Alter Wall, dem Neuen Wall und dem Passagenviertel. 
            In das Gebäudeensemble mit der Adresse Alter Wall 12 zog als erster neuer Mieter das international 
            renommierte Ausstellungshaus Bucerius Kunst Forum ein. Zur Fleetseite hin bietet das Wallter’s gehobene 
            Gastronomie in lässig französischer Brasserie-Atmosphäre. Das Angebot umfasst nicht nur hervorragendes Grass 
            Feed Beef, sondern auch Fisch und vegetarische Alternativen sowie hervorragende Weine. Darüber hinaus 
            eröffnete die von der Modeszene gefeierte Hamburger Designerin Aylin Koenig ihren ersten stationären Shop 
            direkt an der Passage. Der Fokus der Marke liegt auf natürlichen Materialien, hoher Qualität und einem 
            nachhaltigen Mindset. Im Jahr 2020 kam noch eine Interior Kollektion, bestehend aus Vasen und Übertöpfen, 
            hinzu. 2022 hat sie ihren Shop am Alten Wall bereits vergrößert.
          </p> <br>
          <div class="info-box">
            <div class="info">
              <h3 style="color: #077a2d;">1829 m²</h3>
              <p>Bürofläche</p>
            </div>
            <div class="info">
              <h3 style="color: #077a2d;">615 m²</h3>
              <p>Retailfläche</p>
            </div>
          </div>
        </v-card-text>
      </v-card> 

      <!-- Section: Office Info Alter Wall 2 -->
      <v-card class="animated-card">
        <v-card-title>Office</v-card-title>
        <v-card-text>
          <p>Geschossfläche ab: 542 m²</p>
          <p>Etagen: 3. – 6. Obergeschoss</p>
          <p>Besonderheiten: Flexible Büroaufteilung (Zellenbüro, Gruppenbüro etc.),</p>
          <p>Raumhohe Verglasung zum Innenhof, Lichte Raumhöhe von 3 m</p>
        </v-card-text>
      </v-card>

      <!-- Section: Retail Info Alter Wall 2 -->
      <v-card class="animated-card">
        <v-card-title>Retail</v-card-title>
        <v-card-text>
          <p>Geschossfläche ab: 368 m²</p>
          <p>Etagen: Erdgeschoss, Galeriegeschoss</p>
          <p>Shopanzahl: 2</p>
          <p>Besonderheiten: Fronten zum Alten Wall und/oder zum Fleet, Passagendurchgang mit Anbindung an die Fleetbrücke</p>
          <p>Schaufensterfläche: 268,32 m²</p>
          <p>Tür: 25,96 m²</p>
        </v-card-text>
      </v-card>

      <!-- Header Image -->
      <!-- <v-img src="@/assets/Branding/Artinvest/OldWall/ensemble.PNG" /> -->
      <v-img src="@/assets/Branding/Artinvest/alterwall2022.png" alt="Header Image" class="header-image"></v-img>
      <!-- Section: Alter Wall 2-8 -->
      <v-card class="animated-card">
        <v-card-title>Alter Wall 20-22</v-card-title>
        <v-card-text>
          <p>Die größten Flächen im Ensemble finden Sie hier hinter der markanten Neo-Renaissance-Fassade, 
            die dem Gebäude Alter Wall 20-22 eine ganz eigene Note verleiht. Auf den Retail-Flächen findet man 
            das einzigartige englische Kleidermagazin Ladage & Oelke mit einer Auswahl an Qualitätsmarken im 
            anglophilen Stil. Daneben hat unter dem Motto „Ein Platz für dich – morgens, mittags, abends“ das 
            Nachbarschaftsrestaurant Cotidiano eröffnet. Auf 500 m² bietet das Ganztagesrestaurant eine 
            abwechslungsreiche Karte mit hausgemachten Speisen aus hochwertigen Zutaten.
          </p> <br>
          <div class="info-box">
            <div class="info">
              <h3 style="color: #077a2d;">5942 m²</h3>
              <p>Bürofläche</p>
            </div>
            <div class="info">
              <h3 style="color: #077a2d;">3709 m²</h3>
              <p>Retailfläche</p>
            </div>
          </div>
        </v-card-text>
      </v-card> 

      <!-- Section: Office Info Alter Wall 2 -->
      <v-card class="animated-card">
        <v-card-title>Office</v-card-title>
        <v-card-text>
          <p>Geschossfläche ab: 797 m²</p>
          <p>Teilbar ab: 310 m²</p>
          <p>Etagen: 1. – 6. Obergeschoss</p>
          <p>Besonderheiten: Flexible Büroaufteilung (Zellenbüro, Gruppenbüro etc.),</p>
          <p>Raumhohe Verglasung zum Innenhof, Lichte Raumhöhe von 3 m</p>
        </v-card-text>
      </v-card>

      <!-- Section: Retail Info Alter Wall 2 -->
      <v-card class="animated-card">
        <v-card-title>Retail</v-card-title>
        <v-card-text>
          <p>Geschossfläche ab: 137 m²</p>
          <p>Etagen: Erdgeschoss, Galeriegeschoss, Fleetgeschoss</p>
          <p>Shopanzahl: 4</p>
          <p>Besonderheiten: Fronten zum Alten Wall und/oder zum Fleet, Teilweise Anbindung an die Fleetbrücke</p>
          <p>Schaufensterfläche: 66,37 m²</p>
          <p>Tür: 16,44 m²</p>
        </v-card-text>
      </v-card>

        <!-- Header Image -->
      <!-- <v-img src="@/assets/Branding/Artinvest/OldWall/ensemble.PNG" /> -->
      <v-img src="@/assets/Branding/Artinvest/alterwall32.png" alt="Header Image" class="header-image"></v-img>
      <!-- Section: Alter Wall 2-8 -->
      <v-card class="animated-card">
        <v-card-title>Alter Wall 32</v-card-title>
        <v-card-text>
          <p>Das Gebäude Alter Wall 32 war Kontor und Bank und wurde - wie schon um 1900 - ein exklusiver Platz 
            für Einzelhandel und Büroräume in absoluter Top-Lage. Die wunderbare Sicht über das Fleet hin zum 
            Neuen Wall auf der einen und den komplett neugestalteten und verkehrsberuhigten Adolphsplatz auf 
            der anderen Seite öffnet den Blick für neue Wege. Die Top-Lage wird bereichert durch den Shop von 
            Ulrich Stein Einrichtungen, der sich zu allen drei Seiten hin erstreckt. Auf drei Stockwerken 
            präsentiert Ulrich Stein auf eindrucksvolle Weise die It-Pieces von High End Marken wie zum Beispiel 
            Christian Liaigre, MINOTTI, Flexform, Molteni, Promemoria und neue Upcoming-Brands wie Man of Parts 
            sowie seine eigene Linie Ulrich Stein Collection. Mit seinem Team hat der Nobeleinrichter dabei ein 
            Ziel: mit Liebe zum Detail und einer Verpflichtung zu Perfektion für seine anspruchsvolle Klientel 
            individuellen und exklusiven Interior Style zu schaffen.
          </p> <br>
          <div class="info-box">
            <div class="info">
              <h3 style="color: #077a2d;">2775 m²</h3>
              <p>Bürofläche</p>
            </div>
            <div class="info">
              <h3 style="color: #077a2d;">1139 m²</h3>
              <p>Retailfläche</p>
            </div>
          </div>
        </v-card-text>
      </v-card> 

      <!-- Section: Office Info Alter Wall 2 -->
      <v-card class="animated-card">
        <v-card-title>Office</v-card-title>
        <v-card-text>
          <p>Geschossfläche ab: 332 m²</p>
          <p>Teilbar ab: 230 m²</p>
          <p>Etagen: 1. – 6. Obergeschoss</p>
          <p>Besonderheiten: Flexible Büroaufteilung (Zellenbüro, Gruppenbüro etc.),</p>
          <p>Raumhohe Verglasung zum Innenhof, Lichte Raumhöhe von 3 m</p>
        </v-card-text>
      </v-card>

      <!-- Section: Retail Info Alter Wall 2 -->
      <v-card class="animated-card">
        <v-card-title>Retail</v-card-title>
        <v-card-text>
          <p>Geschossfläche ab: 479 m²</p>
          <p>Etagen: Erdgeschoss, Galeriegeschoss, Fleetgeschoss</p>
          <p>Shopanzahl: 4</p>
          <p>Besonderheiten: Fronten zum Aldolfsplatz und zum Alten Wall oder zum Fleet</p>
          <p>Schaufensterfläche: 151,08 m²</p>
          <p>Tür: 31,84 m²</p>
        </v-card-text>
      </v-card>  
    </v-container>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Loader from '@/components/Loader.vue';

export default {
  name: 'AlterWall232Component',
  components: {
    Loader,
  },
  setup() {
    const showLoader = ref(true);
    const router = useRouter();

    const goBack = () => {
      router.go(-1);
    };

    onMounted(() => {
      setTimeout(() => {
        showLoader.value = false;
      }, 800); // Simulate loading
    });

    return {
      showLoader,
      goBack,
    };
  },
};
</script>

<style scoped>
.alterwall-ensemble {
  overflow: hidden;
}

.header-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.animated-card {
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 20px;
  color: #333;
}

.animated-card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.v-card-title {
  font-size: 1.5em;
  font-weight: bold;
}

.v-card-text {
  font-size: 1em;
  color: grey;
}

.info-box {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.info {
  text-align: center;
}

.info h3 {
  margin: 0;
}

.info p {
  margin: 0;
}

.sub-section {
  margin-top: 20px;
}

@media (max-width: 960px) {
  .v-card-title {
    font-size: 1.2em;
  }

  .v-card-text {
    font-size: 0.9em;
  }

  .info-box {
    flex-direction: column;
  }

  .info {
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) {
  .v-card-title {
    font-size: 1em;
  }

  .v-card-text {
    font-size: 0.8em;
  }
}
</style>
