<template>
  <div class="booking-poi-list">
    <Loader :loading="showLoader" />
    <v-container  v-if="showBookingView">
      <v-row dense class="mx-2">
        <!-- Combined Card for Start and End -->
        <v-col cols="12" sm="5" xl="5" lg="5" md="5" @click="openRangeDatePicker">
          <v-card class="date-picker-card" max-width="300" elevation="11" @click.stop="openRangeDatePicker"
            style="background: linear-gradient(71deg, #080509, #1a171c, #080509) !important;">
            <v-card flat class="my-2 mx-2 pa-3 d-flex flex-row flex-wrap align-center justify-space-between"
              style="background: linear-gradient(71deg, #080509, #1a171c, #080509) !important">
              <!-- Start Section -->
              <div class="date-section" style="flex: 1; max-width: 50%; text-align: left">
                <div class="mb-2 d-flex align-center" style="margin-left:5px">
                  <v-icon class="booking-poi-icon" color="#FFD700">mdi-calendar-month-outline</v-icon>
                  <span class="ml-2" style="color: #ffd700">{{ "Start" }}</span>
                </div>
                <div class="my-1 subtitle-2">
                  <v-chip class="ma-2" color="dark" label> {{ moment(range.start).format("ddd. DD.MMM") }} </v-chip>
                  <!-- {{ moment(range.start).format("ddd. DD.MMM") }} -->
                </div>
                <div class="mt-1 caption" style="font-size: 0.9rem !important">
                  <v-chip class="ma-2" color="dark" label> {{ moment(range.start).format("HH:mm") }} </v-chip>
                  <!-- {{ moment(range.start).format("HH:mm") }} -->
                </div>
              </div>

              <!-- End Section -->
              <div class="date-section" style="flex: 1; max-width: 50%; text-align: right">
                <div class="mb-2 d-flex align-center justify-center" style="margin-left:20px">
                  <v-icon class="booking-poi-icon ml-5" color="#FFD700">mdi-calendar-month-outline</v-icon>
                  <span class="ml-2" style="color: #ffd700">{{ "End" }}</span>
                </div>
                <div class="my-1 subtitle-2">
                  <v-chip class="ma-2" color="dark" label> {{ moment(range.end).format("ddd. DD.MMM") }} </v-chip>
                  <!-- {{ moment(range.end).format("ddd. DD.MMM") }} -->
                </div>
                <div class="mt-1 caption" style="font-size: 0.9rem !important">
                  <v-chip class="ma-2" color="dark" label>  {{ moment(range.end).format("HH:mm") }} </v-chip>
                  <!-- {{ moment(range.end).format("HH:mm") }} -->
                </div>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      
    </v-container>

    <v-container>
      <div class="d-flex justify-flex-start align-center" v-if="showBookingView">
        <v-chip class="ma-2" color="dark" label> Available Pois : 4 </v-chip>
        <v-btn color="black" class="ml-4" text @click="openDialog">Filters</v-btn>
      </div>
      <v-row dense v-if="availablePois?.length !== 0">
        <v-col @click="goToPoi(poi)" v-for="poi in availablePois" :key="poi.id" cols="12" sm="6" md="4" lg="4">
          <v-card elevation="2" class="mx-auto my-2" max-width="344" style="background-color: black">
            <v-toolbar dark dense style="background-color: daredrkslategrey;">
              <v-icon size="15" color="green" class="mb-1 ml-2">mdi-checkbox-blank-circle</v-icon>
              <v-toolbar-title style="font-size: 15px" class="white--text">{{ poi?.name }}</v-toolbar-title>
            </v-toolbar>

            <v-list-item class="d-flex align-center" style="margin-top: 0.5rem; margin-left: 0.5rem">
              <v-chip small color="grey darken-1" text-color="white" style="color:white !important">
                {{ poi?.category?.name }}
              </v-chip>
            </v-list-item>

            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-subtitle class="d-flex align-items-center">
                  <v-icon small>mdi-format-list-numbered</v-icon>
                  <span class="caption mx-2">{{ "Floor Number : " }}: {{ poi?.floor?.number }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="d-flex align-items-center mt-1">
                  <v-icon small>mdi-format-list-bulleted-type</v-icon>
                  <span class="caption mx-2">{{ "Floor Name" }}: {{ poi?.floor?.name }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="!poi.category.name.toLowerCase().includes('team') && !poi.category.name.toLowerCase().includes('flex') && !poi.category.name.toLowerCase().includes('buffer')" class="d-flex align-items-center mt-1">
                  <v-icon small>mdi-tag</v-icon>
                  <span class="caption mx-2">{{ "Capacity" }}: {{ poi.capacity }}</span>
                </v-list-item-subtitle>
                <!-- Add more conditions and icons as needed -->
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  
  </div>
</template>

<script>
import { ref, defineComponent, onMounted } from "vue";
import moment from "moment";
import Loader from "@/components/Loader.vue";


export default defineComponent({
  name: "BookingPoisList",
  components: {Loader},
  setup() {
    const showLoader = ref(true);
    onMounted(async () => { 
      showLoader.value = true;
      setTimeout(() => {
        showLoader.value = false;
      }, 2000);
    });
    const showBookingView = ref(true); // Adjust according to your logic
    const range = ref({
      start: new Date(), // Placeholder start date
      end: new Date(), // Placeholder end date
    });
    const availablePoisLength = ref(0);
    const dialog = ref(false);
    const availablePois = ref([
      {
        id: 1,
        name: "Conference Room A",
        category: { name: "Conference Room" },
        floor: { number: "1", name: "First Floor" },
        capacity: 10,
      },
      {
        id: 2,
        name: "Workstation B",
        category: { name: "Open Workspace" },
        floor: { number: "2", name: "Second Floor" },
        capacity: 1,
      },
      {
        id: 3,
        name: "Meeting Room C",
        category: { name: "Meeting Room" },
        floor: { number: "3", name: "Third Floor" },
        capacity: 5,
      },
      {
        id: 4,
        name: "Lounge Area D",
        category: { name: "Lounge" },
        floor: { number: "4", name: "Fourth Floor" },
        capacity: 15,
      },
    ]);

    const openRangeDatePicker = () => {
      // Your logic to open range date picker
    };
    function openDialog() {
      dialog.value = true;
    }
    function applyFilters() {
      // Implement your filter logic here
      dialog.value = false;
    }
    const goToPoi = (poi) => {
      // Navigation logic here
    };

    const getPoiCardDesign = (poi) => {
      if (poi?.color) {
					return poi?.color;
				} else {
					return "black";
				}
    };

    return {
      showBookingView,
      range,
      openRangeDatePicker,
      moment,
      openDialog,
      availablePoisLength,
      availablePois,
      goToPoi,
      getPoiCardDesign,
      showLoader
    };
  },
});
</script>

<style scoped>
.booking-poi-list {
  background: black;
}

.booking-show .v-chip {
  margin-right: 8px;
}
</style>
