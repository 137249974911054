<template>
    <div>
      <Loader :loading="showLoader" />
      <v-toolbar color="#222222" flat dense class="mb-4">
        <v-icon style="margin-left: 10px" size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
        <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
          {{ toobarTitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <img :src="logo" class="toolbar-icon" />
      </v-toolbar>
  
      <v-container class="main-content">
        <v-row justify="center">
          <v-col v-for="(card, index) in cards" :key="index" cols="12" sm="6" md="4">
            <v-card class="elegant-card"
              :class="{ 'active-card': selectedCard === index, 'dim-card': selectedCard !== index && !card.hover }"
              @mouseover="hoverCard(index, true)" @mouseleave="hoverCard(index, false)">
              <img :src="card.image" class="card-image" :class="{ 'card-image-hover': card.hover }" />
              <div class="card-bottom">
                <v-card-title class="card-title">{{ card.text }}</v-card-title>
              </div>
              <v-btn color="primary" class="webcam-button" @click="openWebcam(card.url)">
                Webcam öffnen
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, onUnmounted } from "vue";
  import Loader from "@/components/Loader.vue";
  import { getToolBarLogo } from "@/assets/Branding/branding.js";
  import { useRouter } from "vue-router";
  import hoxtonalterwall from "@/assets/Branding/Artinvest/HotelViewPictures/hoxtonalterwall.jpg";
  import idcologne from "@/assets/Branding/Artinvest/LiveWebcamsPictures/idcologne.jpg";
  import bonn from "@/assets/Branding/Artinvest/LiveWebcamsPictures/bonn.jpg";
  import eschborngate from "@/assets/Branding/Artinvest/LiveWebcamsPictures/eschborngate.jpg";
  import jaho from "@/assets/Branding/Artinvest/LiveWebcamsPictures/jaho.jpg";
  
  export default {
    name: 'LiveWebcamsViewView',
    components: {
      Loader,
    },
    setup() {
      const showLoader = ref(true);
      const logo = ref("");
      const router = useRouter();
      const toobarTitle = ref("L i v e W e b c a m s");
      const selectedCard = ref(null);
      const cards = ref([
        { text: "Alter Wall 40 - Hamburg", image: hoxtonalterwall, hover: false, url: "https://onlineportal.panterra.de/#iss=https%3A%2F%2Faccount.panterra.de%2Fauth%2Frealms%2Ftimefactory" },
        { text: "JaHo", image: jaho, hover: false, url: "https://art-invest-cesa-berlin.webcam-profi.de/" },
        { text: "ID Cologne", image: idcologne, hover: false, url: "https://portal1467.webcam-profi.de/" },
        { text: "Neuer Kanzlerplatz - Bonn", image: bonn, hover: false, url: "http://photowebcam.sietec.ch/construction-camera/606Bonn2/Original57444/webcam.jpg" },
        { text: "Eschborn Gate", image: eschborngate, hover: false, url: "https://portal1839.webcam-profi.de/" },
      ]);
  
      const goBack = () => {
        router.go(-1);
      };
  
      const hoverCard = (index, hover) => {
        cards.value[index].hover = hover;
      };
  
      const openWebcam = (url) => {
        window.open(url, "_blank");
      };
  
      const updateIsMobile = () => {
        const isMobile = window.innerWidth < 1024;
      };
  
      window.addEventListener('resize', updateIsMobile);
  
      onMounted(async () => {
        logo.value = await getToolBarLogo();
        setTimeout(() => {
          showLoader.value = false;
        }, 800);
        updateIsMobile();
      });
  
      onUnmounted(() => {
        window.removeEventListener('resize', updateIsMobile);
      });
  
      return {
        showLoader,
        logo,
        goBack,
        cards,
        hoverCard,
        openWebcam,
        toobarTitle,
      };
    }
  };
  </script>
  
  <style scoped>
  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-right: 20px;
  }
  
  .elegant-card {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s, filter 0.3s;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .elegant-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .active-card {
    filter: brightness(1);
  }
  
  .dim-card {
    filter: brightness(0.5);
  }
  
  .card-image {
    width: 100%;
    height: 70%;
    object-fit: cover;
    transition: transform 0.3s;
  }
  
  .card-image-hover {
    transform: scale(1.1);
  }
  
  .card-bottom {
    background-color: black;
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-title {
    color: white;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
  }
  
  .webcam-button {
    background-color: #077a2d !important; /* Set button color */
    color: white !important;
    cursor: pointer; /* Show hand pointer when hovering over the button */
  }
  </style>