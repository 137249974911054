<template>
    <div class="projects-list">
      <Loader :loading="showLoader" />
      <v-toolbar color="#222222" flat dense class="mb-2" style="cursor: pointer">
        <v-icon color="#077a2d" class="ml-2" size="40" @click="router.go(-1)">mdi-arrow-left-bold-circle</v-icon>
        <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
          {{ "Art-Invest" + "\t" + "Projekte" }}
        </v-toolbar-title>
      </v-toolbar>
      <!-- Transition wrapper for search bar and cards -->
      <transition name="fade" mode="out-in">
        <div>
          <v-container class="px-10 py-5">
            <v-row align="center">
              <v-col cols="9" md="9" lg="10">
                <v-text-field
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  label="Projekte suchen"
                  single-line
                  hide-details
                  variant="solo-inverted"
                  density="compact"
                  class="elegant-search"
                  full-width
                ></v-text-field>
              </v-col>
              <v-col cols="3" md="3" lg="2">
                <v-menu :location="'start'" max-height="500px">
                  <template v-slot:activator="{ props }">
                    <v-btn small color="green" dark v-bind="props" class="filter-button">
                      <v-icon left size="small">mdi-filter</v-icon>
                      Stadt
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="filterByLocation('All')">
                      <v-list-item-title>Alle</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for="location in uniqueLocations" :key="location" @click="filterByLocation(location)">
                      <v-list-item-title>{{ location }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="2" xl="3" v-for="project in filteredProjects" :key="project.id">
                <v-card elevation="4" class="card-hover" style="cursor: pointer">
                  <v-img height="200px" :src="project.featured_image" cover></v-img>
                  <v-card-title>{{ project.title.rendered }}</v-card-title>
                  <v-card-subtitle style="color:green;font-weight: bold">{{ project.place }}</v-card-subtitle>
                  <v-card-subtitle style="line-height: 2.5rem">{{ project.subtitle }}</v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </transition>
    </div>
  </template>
  
  

  <script>
  import { onMounted, ref, computed } from "vue";
  import Loader from "@/components/Loader.vue";
  import { useRouter } from "vue-router";
  import { getArtinvestProjects } from "@/services/ArtinvestFixedData/ArtinvestProjects.js";
  import lodash from "lodash";
  
  export default {
    name: "ProjectsList",
    setup() {
      const router = useRouter();
      const showLoader = ref(true);
      const projectsList = ref([]);
      const search = ref("");
      const locations = ref([]);  // Start with 'All' and prevent duplicates
  
      onMounted(() => {
        let projects = getArtinvestProjects();
        let places = new Set(projects.map(project => project.place.trim()).filter(place => place));
        locations.value = places;
        projectsList.value = projects;
      });
  
      const uniqueLocations = computed(() => locations.value);
  
      let filteredProjects = computed(() => {
        if (!search.value && !currentFilter.value) return projectsList.value;
        return projectsList.value.filter(project =>
          project.title.rendered.toLowerCase().includes(search.value.toLowerCase()) &&
          (!currentFilter.value || currentFilter.value === 'All' || project.place === currentFilter.value)
        );
      });


  
      const currentFilter = ref('All');
  
      const filterByLocation = (location) => {
        currentFilter.value = location;
        if (location === 'All') {
          projectsList.value = getArtinvestProjects(); // Reset to all projects if "All" is selected
        } else {
          projectsList.value = getArtinvestProjects().filter(project => project.place === location);
        }
      };
  
      return {
        router,
        showLoader,
        projectsList,
        search,
        filteredProjects,
        uniqueLocations,
        filterByLocation
      };
    },
  };
  </script>
  


<style scoped>
.filter-button {
  margin-right: 16px; /* Adjust spacing around the button */
  min-width: 90px; /* Adjust minimum width to make the button less wide */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.elegant-search .v-text-field__slot {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
}

.elegant-search input {
  font-size: 16px;
}

.card-hover {
  transition: transform 0.3s ease-in-out;
}

.card-hover:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}
</style>
