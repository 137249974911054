<template>
    <div>
      <Loader :loading="showLoader" />
      <v-toolbar color="#222222" flat dense class="mb-4">
        <v-icon style="margin-left: 10px" size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
        <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
          {{ toobarTitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <img :src="logo" class="toolbar-icon" />
      </v-toolbar>
      <v-container class="main-content">
        <v-row justify="center">
          <v-col v-for="(card, index) in cards" :key="index" cols="12" sm="6" md="4">
            <v-card class="elegant-card"
              :class="{ 'active-card': selectedCard === index, 'dim-card': selectedCard !== index && !card.hover }"
              @mouseover="hoverCard(index, true)" @mouseleave="hoverCard(index, false)" @click="selectCard(index)">
              <img :src="card.image" class="card-image" :class="{ 'card-image-hover': card.hover }" />
              <div class="card-bottom">
                <v-card-title class="card-title">{{ card.text }}</v-card-title>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>

<script>
import { onMounted, ref, onUnmounted, watch } from "vue";
import Loader from "@/components/Loader.vue";
import { getToolBarLogo } from "@/assets/Branding/branding.js";
import { useRouter, useRoute } from "vue-router";
import hoxtonalterwall from "@/assets/Branding/Artinvest/HotelViewPictures/hoxtonalterwall.jpg";
//import digitization from "@/assets/Branding/Artinvest/DigitalizationViewPictures/digitization.png";
import hbk from "@/assets/Branding/Artinvest/DigitalizationViewPictures/hbk.jpeg";

export default {
    name: 'AlterWallFloorsView',
    components: {
    Loader,
  },
  setup() {
    const showLoader = ref(true);
    const logo = ref("");
    const router = useRouter();
    const route = useRoute();
    const selectedCard = ref(0);
    const toobarTitle = ref("A l t e r  W a l l  E t a g e n");
    const cards = ref([
      { text: "Gebäude A - Hotel", image: hoxtonalterwall, hover: false },
      { text: "Gebäude B - Büro", image: hbk, hover: false },
      { text: "Gebäude C - Whonen", image: hbk, hover: false },
      { text: "Gebäude D - Büro", image: hoxtonalterwall, hover: false },
      { text: "Gastes", image: hbk, hover: false },
    ]);

    const goBack = () => {
      router.go(-1);
    };

    const hoverCard = (index, hover) => {
      cards.value[index].hover = hover;
    };

    const selectCard = (index) => {
      selectedCard.value = index;
      if (index === 0) {
        router.push({ name: "ArtInvestApproach" });
      } else if (index === 1) {
        router.push({ name: "ArtInvestExplanation" });
      } else if (index === 2) {
        router.push({ name: "DialInHbk" });
      }
    };

    const isMobile = ref(window.innerWidth < 1024);

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth < 1024;
    };

    window.addEventListener('resize', updateIsMobile);

    onMounted(async () => {
      showLoader.value = true;
      logo.value = await getToolBarLogo();
      setTimeout(() => {
        showLoader.value = false;
      }, 800);
      updateIsMobile();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateIsMobile);
    });

    // Watch for hash changes
    watch(route, (newRoute) => {
      const hash = newRoute.hash;
      if (hash === "#artInvestapproach") {
        selectedCard.value = 1;
        toobarTitle.value = "Unser Ansatz am Alten Wall";
      } else if (hash === "#artInvestexplanation") {
        selectedCard.value = 2;
        toobarTitle.value = "ArtInvest Kurzerklärung";
      } else if (hash === "#dialinhbk") {
        selectedCard.value = 3;
        toobarTitle.value = "Einwahl-HBK";
      } else {
        selectedCard.value = 0;
        toobarTitle.value = "A l t e r  W a l l  E t a g e n";
      }
    }, { immediate: true });

    return {
      showLoader,
      logo,
      goBack,
      cards,
      hoverCard,
      selectedCard,
      selectCard,
      isMobile,
      toobarTitle
    };
  }
};
</script>

<style scoped>
.toolbar-icon {
  height: 30px;
  align-self: center;
  margin-right: 20px;
}

.elegant-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s, filter 0.3s;
  height: 300px;
  /* Fixed height for the card */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
}

.elegant-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.active-card {
  filter: brightness(1);
}

.dim-card {
  filter: brightness(0.5);
}

.card-image {
  width: 100%;
  height: 80%;
  object-fit: cover;
  /* Ensure the image covers the card completely */
  transition: transform 0.3s;
}

.card-image-hover {
  transform: scale(1.1);
  /* Zoom effect on hover */

}

.card-bottom {
  background-color: black;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-title {
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.elegant-chip {
  width: 100%;
  height: 50px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s, filter 0.3s;
  cursor: pointer;
  border-radius: 5px;
}

.elegant-chip:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background: darkseagreen;
  color: black
}

.active-chip {
  background-color: lightgreen;
  filter: brightness(1);
}

.dim-chip {
  background-color: black;
  filter: brightness(0.5);
  color: white
}
</style>
