import { Device } from "@capacitor/device";
import { App } from '@capacitor/app';
import { loadConfig } from "@/configLoader";
import { Preferences } from "@capacitor/preferences";
import * as idb from 'idb'; 

/**
 * Decodes a JWT token to retrieve its payload.
 * @param {string} jwtToken - The JWT token to be decoded.
 * @returns {Object|null} - The decoded payload or null if the token is invalid.
 */
function parseJwt(jwtToken) {
    if (!jwtToken) {
      console.error("Token is not valid.");
      return null;
    }
  
    try {
      const base64Url = jwtToken.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error("Error parsing JWT:", e);
      return null;
    }
  }
  
  /**
   * Checks if a decoded JWT token has expired.
   * @param {Object|null} decodedToken - The decoded JWT token.
   * @returns {boolean} - True if the token has expired, false otherwise.
   */
  function isTokenExpired(decodedToken) {
    if (!decodedToken || !decodedToken.exp) {
      return true;
    }
    const now = new Date().getTime() / 1000; // Current time in seconds since epoch
    return decodedToken.exp < now;
  }


  const  getAppPackageName = async () => {
    const { platform } = await Device.getInfo();
    if(platform === 'web'){
      const config = await loadConfig();
      return config?.clientId;
    }
    else{
      const { id } = await App.getInfo();
      return id;
    }
  }

  async function isWebPlatform() {
    const { platform } = await Device.getInfo();
    return platform === "web";
  }

  async function getStoredItem(key) {
    if (await isWebPlatform()) {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } else {
      const { value } = await Preferences.get({ key });
      return value ? JSON.parse(value) : null;
    }
  }

  async function setStoredItem(key, value) {
    if (await isWebPlatform()) {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      await Preferences.set({ key, value: JSON.stringify(value) });
    }
  }

  const dbPromise = idb.openDB('myAppDatabase', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('blobs')) {
        db.createObjectStore('blobs');
      }
    }
  });
  
  async function storeBlob(key, blob) {
    const db = await dbPromise;
    const tx = db.transaction('blobs', 'readwrite');
    tx.objectStore('blobs').put(blob, key);
    await tx.complete;
  }
  
  async function retrieveBlob(key) {
    const db = await dbPromise;
    return db.transaction('blobs').objectStore('blobs').get(key);
  }

  async function clearAllStorage() {
    if (await isWebPlatform()) {
        sessionStorage.clear();
        localStorage.clear();
    } else {
        await Preferences.clear();
    }
    const db = await dbPromise;
    const tx = db.transaction('blobs', 'readwrite');
    tx.objectStore('blobs').clear();
    await tx.complete;
}
  
  export { clearAllStorage, getAppPackageName,parseJwt, isTokenExpired, isWebPlatform, getStoredItem, setStoredItem, storeBlob, retrieveBlob, dbPromise};
  