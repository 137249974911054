import { ref } from "vue";
import { Browser } from "@capacitor/browser";
import { loadConfig } from "@/configLoader"; // Ensure this path is correct
import { Device } from "@capacitor/device";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { Preferences } from "@capacitor/preferences";
import { InAppBrowser } from "@capgo/inappbrowser";
import { parseJwt, isTokenExpired,clearAllStorage  } from "./utils";

const token = ref(null);
const isWebPlatform = (platform) => platform === "web";

async function getStorage() {
  const { platform } = await Device.getInfo();
  return platform === "web" ? window.localStorage : SecureStoragePlugin;
}

async function getToken() {
  const platform = (await Device.getInfo()).platform;
  const key = "pinestack.token";

  if (isWebPlatform(platform)) {
    return window.localStorage.getItem(key);
  } else {
    const { value } = await Preferences.get({ key });
    return value;
  }
}

async function setToken(tokenValue) {
  const platform = (await Device.getInfo()).platform;
  const key = "pinestack.token";

  if (isWebPlatform(platform)) {
    window.localStorage.setItem(key, tokenValue);
  } else {
    await Preferences.set({ key, value: tokenValue });
  }

  token.value = tokenValue;
}

async function removeToken() {
  const platform = (await Device.getInfo()).platform;
  const key = "pinestack.token";
  if (isWebPlatform(platform)) {
    window.localStorage.removeItem(key);
  } else {
    await Preferences.remove({ key });
  }
  token.value = null;
}

async function authenticate() {
  const config = await loadConfig();
  const { platform } = await Device.getInfo();

  //const callbackUri = platform === 'web' ? `${window.location.origin}/oauth2/code/callback` : "https://operations.hammerbrooklyn.pinestack.eu/oauth2/code/callback";
  const callbackUri =
    platform === "web" && window.location.href?.includes("localhost")
      ? config.localRedirectUri
      : config.redirectUri;
  const authUrl = `${config.authorizationUri}?client_id=${encodeURIComponent(
    config.clientId
  )}&redirect_uri=${callbackUri}&response_type=token&scope=email profile`;

  if (platform === "web") {
    window.open(authUrl, "_self");
  } else {
    const options = {
      url: authUrl,
      closeButtonColor: "#ffffff",
      activeNativeNavigationForWebview: false,
      disableGoBackOnNativeApplication: false,
      isPresentAfterPageLoad: false,
      isAnimated: false,
      showReloadButton: false,
      visibleTitle: false,
      toolbarColor: "white",
      showArrow: false,
    };

    await InAppBrowser.openWebView(options);
    InAppBrowser.addListener("urlChangeEvent", async (info) => {
      console.log(info);
      if (info.url.includes("access_token")) {
        InAppBrowser.close();
        if (info.url.includes("access_token")) {
          const tokenValue = info.url.split("access_token=")[1].split("&")[0];
          await setToken(tokenValue);
          window.location.href = "/";
        }
      }
    });
  }
}
async function getUserProfile() {
  let access_token = await getToken();
  const userProfile = access_token ? parseJwt(access_token) : null;
  return userProfile;
}

export function useOAuthService() {
  return {
      authenticate,
      getToken,
      setToken,
      removeToken,
      isLoggedIn: async () =>
          !!(await getToken()) && !isTokenExpired(parseJwt(await getToken())),
      logOut: async () => {
          await removeToken();
          await clearAllStorage();
      },
      getUserProfile,
  };
}
