<template>
  <v-card class="mx-auto booking-view" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <v-toolbar class="px-0" color="#222222">
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title>Booking</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-reload</v-icon>
      </v-btn>

      <!-- Using v-slot:extension for tabs -->
      <template v-slot:extension>
        <v-tabs v-model="tabs" color="primary" grow>
          <v-tab :value="0">
            <v-icon>mdi-format-list-bulleted</v-icon>
            Book Now
          </v-tab>
          <v-tab :value="1">
            <v-icon>mdi-format-line-spacing</v-icon>
            Bookings
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-window v-model="tabs">
      <v-window-item :value="0">
        <v-card>
          <BookingPoisList/>
        </v-card>
      </v-window-item>
      <v-window-item :value="1">
        <v-card>
          <UserBookings/>
        </v-card>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import { ref, defineComponent, watch,onMounted } from "vue";
import UserBookings from "@/components/UserBookings.vue";
import BookingPoisList from "@/components/BookingPoisList.vue";
import { getToolBarLogo } from "@/assets/Branding/branding.js";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "BookingView",
  components: {
    BookingPoisList,
    UserBookings,
  },
  setup() {
    const tabs = ref(0); // Starts with Book Now tab
    const router = useRouter();
    let startX = 0;
    const logo = ref("");

    const handleTouchStart = (e) => {
      startX = e.touches[0].clientX; // Get the first touch point
    };

    const handleTouchEnd = (e) => {
      const endX = e.changedTouches[0].clientX; // Get the point where touch ended
      const diffX = startX - endX; // Calculate the difference in start and end position

      if (diffX > 50) { // Swipe Left
        tabs.value = Math.min(tabs.value + 1, 1); // Increment tabs index, with a max of 1
      } else if (diffX < -50) { // Swipe Right
        tabs.value = Math.max(tabs.value - 1, 0); // Decrement tabs index, with a min of 0
      }
    };

    // Watch for tab changes to adjust routing
    watch(tabs, (newValue) => {
      if (newValue === 0) {
        router.push({ name: "BookingPoisList" });
      } else {
        router.push({ name: "UserBookings" });
      }
    });

    // Initialize component with specific tab based on the route
    const initializeComponent = () => {
      if (router.currentRoute.value.name === "BookingPoisList") {
        tabs.value = 0; // Corresponds to <BookingPoisList>
      } else if (router.currentRoute.value.name === "UserBookings") {
        tabs.value = 1; // Corresponds to <UserBookings>
      }
    };

    onMounted(() => {
      logo.value = getToolBarLogo();
      initializeComponent();
      handleTouchStart,
      handleTouchEnd
    });

    return {
      tabs,
      logo
    };
  },
});
</script>

<style scoped>
.booking-view {
  background: black;
}
.toolbar-icon {
  height: 30px;
  align-self: center;
  margin-left: 1rem;
}
</style>
