<template>

  <Loader :loading="showLoader" />
  <v-toolbar color="#222222" flat dense class="mb-2" style="cursor: pointer">
    <v-icon color="#077a2d" class="ml-2" size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
    <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
      {{ "Alter Wall Historie" }}
    </v-toolbar-title>
  </v-toolbar>

  <div class="container">
    <div v-if="isMobile" class="button-wrapper">
      <v-btn :class="{ 'active-button': activeCard === 1, 'inactive-button': activeCard !== 1 }" @click="setActiveCard(1)"
        outlined
        style="flex: 1; margin: 10px; border-radius: 15px; font-size: 1em; padding: 10px; text-align: center; transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s;">
        Historie bis 1900
      </v-btn>
      <v-btn :class="{ 'active-button': activeCard === 2, 'inactive-button': activeCard !== 2 }" @click="setActiveCard(2)"
        outlined
        style="flex: 1; margin: 10px; border-radius: 15px; font-size: 1em; padding: 10px; text-align: center; transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s;">
        Historie ab 1900
      </v-btn>
    </div>
    <v-row v-else>
      <v-col cols="12" md="6" sm="6">
        <v-card :class="{ 'active-card': activeCard === 1, 'inactive-card': activeCard !== 1 }" @click="setActiveCard(1)"
          class="hover-effect card-elegant">
          <v-card-title class="card-title">Historie bis 1900</v-card-title>
          <v-card-text class="card-text">
            Von der großen Burstah bis zum Ausbau der Börse.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-card :class="{ 'active-card': activeCard === 2, 'inactive-card': activeCard !== 2 }" @click="setActiveCard(2)"
          class="hover-effect card-elegant">
          <v-card-title class="card-title">Historie ab 1900</v-card-title>
          <v-card-text class="card-text">
            Vom Alter Wall als Ort des Einzelhandels zum Wiederaufbau nach dem zweiten Weltkrieg.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <AlterWall232History :activeCard="activeCard" />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from "vue-router";
import AlterWall232History from './ChildComponents/AlterWall232History.vue';

export default {
  components: {
    AlterWall232History,
  },
  setup() {
    const activeCard = ref(1);
    const router = useRouter();
    const isMobile = ref(window.innerWidth < 600);

    const handleResize = () => {
      isMobile.value = window.innerWidth < 600;
    };

      const goBack = () => {
      router.go(-1);
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    const setActiveCard = (cardNumber) => {
      activeCard.value = cardNumber;
    };

    return {
      activeCard,
      isMobile,
      goBack,
      setActiveCard
    };
  }
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.card-elegant {
  min-height: 200px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: white;
  transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-title {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.card-text {
  padding: 20px;
  font-size: 1em;
  color: #333;
  text-align: center;
}

.active-card {
  border: 2px solid #077a2d;
  opacity: 1;
}

.inactive-card {
  border: 2px solid #ddd;
  opacity: 0.5;
}

.button-wrapper {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  padding: 10px;
}

.active-button {
  border: 2px solid #077a2d;
  opacity: 1;
}

.inactive-button {
  border: 2px solid #ddd;
  opacity: 0.5;
}

.hover-effect:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .card-title {
    font-size: 1.5em;
  }

  .card-text {
    font-size: 0.9em;
  }
}

@media (max-width: 600px) {
  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px;
  }

  .button-elegant {
    flex: 1;
    margin: 10px;
    border-radius: 15px;
    font-size: 1em;
    padding: 10px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s;
  }
}
</style>