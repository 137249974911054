<template>
  <div class="locks-view">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" prominent elevation="5">
      <v-toolbar-title>Access Control</v-toolbar-title>
      <v-btn color="primary" text @click="resync"> Re-Sync </v-btn>
    </v-toolbar>
  </div>

    
</template>

<script>

import Loader from "@/components/Loader.vue";
import { defineComponent, onMounted, ref } from "vue";

import lodash from "lodash";

export default defineComponent({
  name: "LocksView",
  components: {
    Loader,
  },
  setup() {
    
    const showLoader = ref(false);
    
    onMounted(async () => {
     
    });

    return {  
      showLoader,
    
    };
  },
});
</script>

<style scoped>

</style>
