// Importing video files
import video101ClevelandLondon from '@/assets/Branding/Artinvest/videos/101_cleveland_london.mp4';
import videoAlterWallHamburg from '@/assets/Branding/Artinvest/videos/Making-of-Film - Alter Wall (Hamburg).mp4';
// import videoAlsterFoyerHamburg from '@/assets/Branding/Artinvest/videos/Making-of-Film - Alsterfoyer (Hamburg).mp4';
import videoHammerbrooklyn from '@/assets/Branding/Artinvest/videos/HAMMERBROOKLYN.Digital Pavillon Teaser Video.mp4';
// import video25hoursFlorence from '@/assets/Branding/Artinvest/videos/Making-of-Film - 25hours Piazza San Paolino (Florenz).mp4';
// eslint-disable-next-line
// import videoMachereiMunich from '@/assets/Branding/Artinvest/videos/Making-of-Film - Die Macherei (München).mp4';
// import videoMorrowFrankfurt from '@/assets/Branding/Artinvest/videos/Making-of-Film - MORROW (Frankfurt).mp4';
// import videoFurstFriedrichDusseldorf from '@/assets/Branding/Artinvest/videos/Making-of-Film -  Fürst und Friedrich (Düsseldorf).mp4';
// import videoKaiserHofCologne from '@/assets/Branding/Artinvest/videos/Making-of-Film -  Kaiser Hof (Köln).mp4';
// import videoAtlasMunich from '@/assets/Branding/Artinvest/videos/Making-of ATLAS, München.mp4';
// import videoKlopperhausHamburg from '@/assets/Branding/Artinvest/videos/Making-of Klöpperhaus, Hamburg.mp4';
// import video52HiCologne from '@/assets/Branding/Artinvest/videos/Making-of 52HI, Köln.mp4';

const videos = [
  {
    id: 1,
    title: "101 on Cleveland, London",
    thumbnail: "https://www.art-invest.de/wp-content/uploads/2023/12/101Cleveland_London.jpg",
    video: video101ClevelandLondon,
    options: {
      src: video101ClevelandLondon,
      poster: "https://www.art-invest.de/wp-content/uploads/2023/12/101Cleveland_London.jpg",
      width: "800px",
      height: "450px",
      control: true,
      controlBtns: ["audioTrack", "volume", "fullScreen"],
      lang: 'en'
    },
  },
  {
    id: 2,
    title: "Alter Wall, Hamburg",
    thumbnail: "https://www.art-invest.de/wp-content/uploads/2023/12/AlterWall_Hamburg.jpg",
    video: videoAlterWallHamburg,
    options: {
      src: videoAlterWallHamburg,
      poster: "https://www.art-invest.de/wp-content/uploads/2023/12/AlterWall_Hamburg.jpg",
      width: "800px",
      height: "450px",
      control: true,
      controlBtns: ["audioTrack", "volume", "fullScreen"],
    },
  },
  {
    id: 3,
    title: "Alster Foyer, Hamburg",
    thumbnail: "https://www.art-invest.de/wp-content/uploads/2023/12/Alsterfoyer_Hamburg.jpg",
    video: video101ClevelandLondon,
    options: {
      src: video101ClevelandLondon,
      poster: "https://www.art-invest.de/wp-content/uploads/2023/12/Alsterfoyer_Hamburg.jpg",
      width: "800px",
      height: "450px",
      control: true,
      controlBtns: ["audioTrack", "volume", "fullScreen"],
    },
  },
  {
    id: 4,
    title: "Hammerbrooklyn.Digital Campus, Hamburg",
    thumbnail: "https://www.art-invest.de/wp-content/uploads/2023/12/Hammerbrooklyn_Hamburg.jpg", // Update this URL as needed
    video: videoHammerbrooklyn,
    options: {
      src: videoHammerbrooklyn,
      poster: "https://www.art-invest.de/wp-content/uploads/2023/12/Hammerbrooklyn_Hamburg.jpg", // Update this URL as needed
      width: "800px",
      height: "450px",
      control: true,
      controlBtns: ["audioTrack", "volume", "fullScreen"],
    },
  },
  {
    id: 5,
    title: "25hours Piazza San Paolino, Florence",
    thumbnail: "https://www.art-invest.de/wp-content/uploads/2023/12/25h_Florenz.jpg", // Update this URL as needed
    video: video101ClevelandLondon,
    options: {
      src: video101ClevelandLondon,
      poster: "https://www.art-invest.de/wp-content/uploads/2023/12/25h_Florenz.jpg", // Update this URL as needed
      width: "800px",
      height: "450px",
      control: true,
      controlBtns: ["audioTrack", "volume", "fullScreen"],
    },
  },
  {
    id: 6,
    title: "The Macherei Munich",
    thumbnail: "https://www.art-invest.de/wp-content/uploads/2024/03/Die_Macherei_Muenchen.jpg", // Update this URL as needed
    video: video101ClevelandLondon,
    options: {
      src: video101ClevelandLondon,
      poster: "https://www.art-invest.de/wp-content/uploads/2024/03/Die_Macherei_Muenchen.jpg", // Update this URL as needed
      width: "800px",
      height: "450px",
      control: true,
      controlBtns: ["audioTrack", "volume", "fullScreen"],
    },
  },
  {
    id: 7,
    title: "MORROW, Frankfurt",
    thumbnail: "https://www.art-invest.de/wp-content/uploads/2023/12/Morrow_Frankfurt.jpg", // Update this URL as needed
    video: video101ClevelandLondon,
    options: {
      src: video101ClevelandLondon,
      poster: "https://www.art-invest.de/wp-content/uploads/2023/12/Morrow_Frankfurt.jpg", // Update this URL as needed
      width: "800px",
      height: "450px",
      control: true,
      controlBtns: ["audioTrack", "volume", "fullScreen"],
    },
  },
  {
    id: 8,
    title: "Furst and Friedrich, Düsseldorf",
    thumbnail: "https://www.art-invest.de/wp-content/uploads/2023/12/Fuerst_und_Friedrich_Duesseldorf.jpg", // Update this URL as needed
    video: video101ClevelandLondon,
    options: {
      src: video101ClevelandLondon,
      poster: "https://www.art-invest.de/wp-content/uploads/2023/12/Fuerst_und_Friedrich_Duesseldorf.jpg", // Update this URL as needed
      width: "800px",
      height: "450px",
      control: true,
      controlBtns: ["audioTrack", "volume", "fullScreen"],
    },
  },
  {
    id: 9,
    title: "Kaiser Hof, Cologne",
    thumbnail: "https://www.art-invest.de/wp-content/uploads/2023/12/Kaiserhof_Koeln.jpg", // Update this URL as needed
    video: video101ClevelandLondon,
    options: {
      src: video101ClevelandLondon,
      poster: "https://www.art-invest.de/wp-content/uploads/2023/12/Kaiserhof_Koeln.jpg", // Update this URL as needed
      width: "800px",
      height: "450px",
      control: true,
      controlBtns: ["audioTrack", "volume", "fullScreen"],
    },
  },
  {
    id: 10,
    title: "ATLAS, Munich",
    thumbnail: "https://www.art-invest.de/wp-content/uploads/2023/12/Atlas_Muenchen.jpg", // Update this URL as needed
    video: video101ClevelandLondon,
    options: {
      src: video101ClevelandLondon,
      poster: "https://www.art-invest.de/wp-content/uploads/2023/12/Atlas_Muenchen.jpg", // Update this URL as needed
      width: "800px",
      height: "450px",
      control: true,
      controlBtns: ["audioTrack", "volume", "fullScreen"],
    },
  },
  {
    id: 11,
    title: "KLÖPPERHAUS, Hamburg",
    thumbnail: "https://www.art-invest.de/wp-content/uploads/2023/12/Kloepperhaus_Hamburg.jpg", // Update this URL as needed
    video: video101ClevelandLondon,
    options: {
      src: video101ClevelandLondon,
      poster: "https://www.art-invest.de/wp-content/uploads/2023/12/Kloepperhaus_Hamburg.jpg", // Update this URL as needed
      width: "800px",
      height: "450px",
      control: true,
      controlBtns: ["audioTrack", "volume", "fullScreen"],
    },
  },
  {
    id: 12,
    title: "52HI, Cologne",
    thumbnail: "https://www.art-invest.de/wp-content/uploads/2023/12/52HI_Koeln.jpg", // Update this URL as needed
    video: video101ClevelandLondon,
    options: {
      src: video101ClevelandLondon,
      poster: "https://www.art-invest.de/wp-content/uploads/2023/12/52HI_Koeln.jpg", // Update this URL as needed
      width: "800px",
      height: "450px",
      control: true,
      controlBtns: ["audioTrack", "volume", "fullScreen"],
    },
  },
];

export default videos;
