<template>
  <v-app>
    <v-main :style="{ paddingBottom: showBottomNav ? 'var(--bottom-nav-height)' : '0px' }">
      <BottomNavBar
        v-model="currentTab"
        :tabs="tabs"
        :showBottomNav="showBottomNav"
        v-if="route.name !== 'Login' && route.fullPath !== '/oauth2' && route.fullPath !== '/login'"
      />
      <router-view v-slot="{ Component }">
        <transition name="slide" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import BottomNavBar from '@/components/BottomNavBar.vue';

export default {
  components: {
    BottomNavBar,
  },
  setup() {
    const route = useRoute();
    const showBottomNav = ref(true);
    const currentTab = ref(null);

    const tabs = [
      { label: 'News', value: 'infocenter', icon: 'mdi-newspaper-variant-outline' },
      { label: 'Office', value: 'building', icon: 'mdi-office-building-marker-outline' },
      { label: 'Booking', value: 'booking', icon: 'mdi-calendar-month-outline' },
      { label: 'Menu', value: 'menu', icon: 'mdi-menu-open' },
    ];

    onMounted(() => {
      if (route.path === '/' || route.path.startsWith('/home')) {
        currentTab.value = 'home';
      }
      console.log(JSON.parse(JSON.stringify(route)));
    });

    return {
      tabs,
      route,
      showBottomNav,
      currentTab,
    };
  },
};
</script>

<style scoped>
/* Your styles here */
</style>
