<template>
    <div class="background">
       
    </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";


export default defineComponent({
    name: "RoomControlView",
    components: { },
    setup() {
        
        onMounted(async () => { });

        return { };
    },
});
</script>

<style scoped>

</style>
