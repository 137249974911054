<template>
  <div class="artinvest-view">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-4" style="cursor: pointer">
      <v-icon color="#077a2d" class="ml-2" size="40" @click="router.go(-1)"
        >mdi-arrow-left-bold-circle</v-icon
      >

      <v-toolbar-title
        style="font-family: 'Material Design Icons'; font-size: 25px !important"
        >{{ "Art-Invest" }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container>
      <ul class="cards">
        <li>
          <a @click="goToDetails('projects')" class="card">
            <img
              :src="
                require('@/assets/Branding/Artinvest/artinvest_projects_collage.jpg')
              "
              class="card__image"
              alt=""
            />
            <div class="card__overlay">
              <div class="card__header">
                <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 40 80 c 22 0 40 -22 40 -40 v 40 Z"
                    fill="var(--surface-color)"
                  />
                </svg>

                <img
                  class="card__thumb"
                  :src="
                    require('@/assets/Branding/Artinvest/HomeViewPictures/artinvest_buildings_icon.png')
                  "
                  alt=""
                />
                <div class="card__header-text">
                  <h3 class="card__title">Projekte</h3>
                </div>
              </div>
              <p class="card__description">
                Jedes einzelne Projekt erzählt die Geschichte einer Entwicklung, die sich in der Planungsphase befindet, noch im Gange ist oder bereits abgeschlossen ist.
              </p>
            </div>
          </a>
        </li>
        <li class="card--video">
          <a @click="goToDetails('films')" class="card">
            <!-- <img
              src="https://i.imgur.com/2DhmtJ4.jpg"
              class="card__image"
              alt=""
            /> -->
            <video class="card__image" autoplay loop muted playsinline>
              <source :src="'https://www.art-invest.de/wp-content/uploads/2022/01/Art-Invest-Intro_Film-Website.mp4'" type="video/mp4">
              Your browser does not support the video tag.
            </video>
            <div class="card__overlay">
              <div class="card__header">
                <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 40 80 c 22 0 40 -22 40 -40 v 40 Z"
                    fill="var(--surface-color)"
                  />
                </svg>
                <img
                  class="card__thumb"
                  :src="
                    require('@/assets/Branding/Artinvest/HomeViewPictures/artinvest_films_icon.png')"
                  alt=""
                />
                <div class="card__header-text">
                  <h3 class="card__title">Filme</h3>
                </div>
              </div>
              <p class="card__description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Asperiores, blanditiis?
              </p>
            </div>
          </a>
        </li>
        
        <li>
          <a @click="goToDetails('esg')"  class="card">
            <img
              :src="
                require('@/assets/Branding/Artinvest/HomeViewPictures/artinvest_esg_cover.jpg')"
              class="card__image"
              alt=""
            />
            <div class="card__overlay">
              <div class="card__header">
                <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 40 80 c 22 0 40 -22 40 -40 v 40 Z"
                    fill="var(--surface-color)"
                  />
                </svg>
                <img
                  style="flex-shrink: 0; width: 80px; height: 70px; border-radius: 50%;"
                  :src="
                    require('@/assets/Branding/Artinvest/HomeViewPictures/artinvest_esg_icon.png')"
                  alt=""
                />
                <div class="card__header-text">
                  <h3 class="card__title">ESG</h3>
                  <span class="card__status">Management für mehr Nachhaltigkeit</span>
                </div>
              </div>
              <p class="card__description">
                Art-Invest Real Estate steht für Nachhaltigkeit durch innovative, zukunftsorientierte Immobilienentwicklung, die den wirtschaftlichen Erfolg mit einem hohen Maß an ökologischer und sozialer Verantwortung in Einklang bringt.
              </p>
            </div>
          </a>
        </li>
      </ul>
    </v-container>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import Loader from "@/components/Loader.vue";


export default {
  name: "ArtInvestView",
  components: {
    Loader,
  },
  setup() {
    const router = useRouter();
    const showLoader = ref(true);
    const goToDetails = (route) => {
      if(route === 'projects'){
        router.push({ name: 'ProjectsList' }).catch((err) => console.error("Routing error:", err));
      }
      if(route === 'films'){
        router.push({ name: 'ProjectFilmsList' }).catch((err) => console.error("Routing error:", err));
      }
      if(route === 'esg'){
        router.push({ name: 'ESG' }).catch((err) => console.error("Routing error:", err));
      }
    };

    onMounted(() => {
      setTimeout(() => {
       showLoader.value = false;
      }, 1200);
    });

    return {
      router,
      goToDetails,
      showLoader
    };
  },
};
</script>

<style scoped>
:root {
  --surface-color: #fff; /* White background */
  --curve: 40; /* Adjust this value to change the curvature */
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 5vw;
  padding: 0;
  list-style-type: none;
}

.card {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
}

.card__image {
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: white;
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) calc(var(--curve) * 1px) 0 0;
  background-color: white;
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}

.card__arc {
  position: absolute;
  bottom: 100%; /* This positions the arc correctly */
  right: 0;
  z-index: 2; /* Higher than .card__overlay to create the cut-out effect */
  width: 80px;
  height: 80px;
}

.card__arc path {
  fill: white; /* Ensure this is the same as the .card__overlay background */
}

.card:hover .card__header {
  transform: translateY(0);
}

.card--video .card__image {
  width: 100%;
  height: 100%; 
  object-fit: cover; 
}


.card__image video {
  display: block;
}



.card__thumb {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.card__title {
  font-size: 1em;
  margin: 0 0 0.3em;
  color: #6a515e;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #0ff,
    0 0 80px #0ff, 0 0 90px #0ff, 0 0 100px #0ff, 0 0 150px #0ff;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";
  font-size: 0.8em;
  color: #d7bdca;
}

.card__status {
  font-size: 0.8em;
  color: #d7bdca;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #d7bdca;
  font-family: "MockFlowFont";
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
</style>
