<template>
  <div class="hoxton-alter-wall">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-2" style="cursor: pointer">
      <v-icon color="#077a2d" class="ml-2" size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
      <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
        {{ "The Hoxton Alter Wall" }}
      </v-toolbar-title>
    </v-toolbar>

    <v-container>
      <v-row class="button-row">
        <v-col v-for="(item, index) in items" :key="index" class="button-col" cols="12" sm="6" md="3">
          <v-btn class="presentation-button" :class="{ 'active-button': activeItem === item.name }"
            @click="openPresentation(item)">
            <span>{{ item.name }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" persistent max-width="90%" max-height="90%" class="fullscreen-dialog">
      <v-card>
        <v-toolbar dense style="background-color: #077a2d;" dark>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ currentTitle }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <iframe v-if="currentLink" :src="currentLink" frameborder="0" class="presentation-iframe" ref="iframe"
            allowfullscreen></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue';  // Import ref here
import { useRouter } from 'vue-router'; // Import useRouter
import Loader from '@/components/Loader.vue';

export default {
  name: "HoxtonAlterWall",
  components: {
    Loader,
  },
  setup() {
    const router = useRouter(); // Use useRouter for routing
    const dialog = ref(false);
    const currentLink = ref('');
    const currentTitle = ref('');
    const activeItem = ref('');
    const items = ref([
      { name: "Idee", link: "https://slidesgo.com/theme/minimal-hepatitis-clinical-case#search-Simple&position-3&results-5034" },
      { name: "Zimmer", link: "https://example.com/Zimmer.pptx" },
      { name: "Restaurant", link: "https://example.com/Restaurant.pptx" },
      { name: "Lobby", link: "https://example.com/Lobby.pptx" },
    ]);

    const openPresentation = (item) => {
      currentLink.value = item.link;
      currentTitle.value = item.name;
      activeItem.value = item.name;
      dialog.value = true;
    };

    const goBack = () => {
      router.go(-1); // Navigate back
    };

    return {
      dialog,
      currentLink,
      currentTitle,
      activeItem,
      items,
      openPresentation,
      goBack
    };
  },
};
</script>

<style scoped>

.title {
  margin-bottom: 2rem;
  font-size: 2rem;
  margin-top: 2rem;
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; /* Center align items vertically */
  gap: 1rem;
  min-height: 50vh; /* Adjust to center vertically */
  margin-top: 2rem;
}

.button-col {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.presentation-button {
  width: 100%;
  height: 100px;
  background: linear-gradient(135deg, #077a2d 0%, #a8e063 100%);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s, box-shadow 0.3s;
  border: none;
  border-radius: 8px;
}

.presentation-button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.active-button {
  background: linear-gradient(135deg, #4CAF50 0%, #8BC34A 100%);
  color: white;
}

.presentation-iframe {
  width: 100%;
  height: 80vh;
}

@media (max-width: 960px) {
  .button-col {
    max-width: 50%;
  }
}

@media (max-width: 600px) {
  .button-col {
    max-width: 100%;
  }

  .presentation-iframe {
    height: 60vh;
  }
}
</style>
