<template>
  <div class="card-hover" :style="{ backgroundImage: `url(${getCardImage(index)})` }">
    <div class="card-hover__content">
      <h4 class="card-hover__title">
        {{ card.title }} <span class="card-hover__restaurant">{{ card.text }}</span>
      </h4>
      <a href="#" class="card-hover__link" @click.prevent="viewPdf(card.link)">
        <span>{{ card.linkText }}</span>
        <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
        </svg>
      </a>
    </div>
    <div class="card-hover__extra">
      <h6>{{ card.extraText }} <span>{{ card.extraHighlight }}</span> {{ card.discount }} <span>{{ card.extraDiscount }}</span></h6>
    </div>
    <div v-if="userPosition && card.lat && card.lng" class="distance-info">
      <div class="distance-item">
        <v-icon>mdi-walk</v-icon>
        <span>{{ walkingDistance }}</span>
      </div>
      <div class="distance-item">
        <v-icon>mdi-car</v-icon>
        <span>{{ drivingDistance }}</span>
      </div>
      <div class="distance-item">
        <v-icon>mdi-train</v-icon>
        <span>{{ transitDistance }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "RestaurantCard",
  props: {
    card: Object,
    index: Number,
    getCardImage: Function,
    viewPdf: Function,
    userPosition: Object
  },
  setup(props) {
    const walkingDistance = ref("Calculating...");
    const drivingDistance = ref("Calculating...");
    const transitDistance = ref("Calculating...");

    const calculateDistance = async (lat1, lng1, lat2, lng2, mode) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=${lat1},${lng1}&destinations=${lat2},${lng2}&mode=${mode}&key=AIzaSyBgm4lMPo33kPSW37a7gtTyCU1Hvy9le7Q`
        );
        const data = await response.json();
        if (data.rows[0].elements[0].status === "OK") {
          return data.rows[0].elements[0].distance.text;
        } else {
          return "N/A";
        }
      } catch (error) {
        console.error(error);
        return "N/A";
      }
    };

    const updateDistances = async () => {
      if (props.userPosition && props.card.lat && props.card.lng) {
        walkingDistance.value = await calculateDistance(props.userPosition.lat, props.userPosition.lng, props.card.lat, props.card.lng, 'walking');
        drivingDistance.value = await calculateDistance(props.userPosition.lat, props.userPosition.lng, props.card.lat, props.card.lng, 'driving');
        transitDistance.value = await calculateDistance(props.userPosition.lat, props.userPosition.lng, props.card.lat, props.card.lng, 'transit');
      }
    };

    watch(() => props.userPosition, updateDistances, { immediate: true });

    return {
      walkingDistance,
      drivingDistance,
      transitDistance
    };
  }
});
</script>

<style scoped>
.card-hover {
  width: 100%;
  height: 400px;
  max-width: 600px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 32px -10px rgba(0, 0, 0, 0.08);
  background-size: cover;
  background-position: center;
}

.card-hover__content {
  width: 100%;
  text-align: center;
  background-color: #86B971;
  padding: 0 60px 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(0);
  transition: all 0.35s 0.35s cubic-bezier(.1, .72, .4, .97);
  will-change: bottom, background-color, transform, padding;
  z-index: 1;
}

.card-hover__content::before,
.card-hover__content::after {
  content: '';
  width: 100%;
  height: 120px;
  background-color: inherit;
  position: absolute;
  left: 0;
  z-index: -1;
}

.card-hover__content::before {
  top: -80px;
  clip-path: ellipse(60% 80px at bottom center);
}

.card-hover__content::after {
  bottom: -80px;
  clip-path: ellipse(60% 80px at top center);
}

.card-hover__title {
  font-size: 1.5rem;
  margin-bottom: 1em;
  color: black;
}

.card-hover__restaurant {
  font-weight: bold;
  color: #2d7f0b;
}

.card-hover__link {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, 10%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;
  color: #2d7f0b;
  opacity: 0;
  padding: 10px;
  transition: all 0.35s;
}

.card-hover__link:hover svg {
  transform: translateX(4px);
}

.card-hover__link svg {
  width: 18px;
  margin-left: 4px;
  transition: transform 0.3s;
}

.card-hover__extra {
  height: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
  background-color: #86b971;
  padding: 80px;
  bottom: 0;
  z-index: 0;
  color: #dee8c2;
  transform: translateY(100%);
  will-change: transform;
  transition: transform 0.35s;
}

.card-hover__extra span {
  color: #2d7f0b;
}

.card-hover:hover .card-hover__content {
  background-color: #DEE8C2;
  bottom: 100%;
  transform: translateY(100%);
  padding: 50px 60px;
  transition: all 0.35s cubic-bezier(.1, .72, .4, .97);
}

.card-hover:hover .card-hover__link {
  opacity: 1;
  transform: translate(-50%, 0);
  transition: all 0.3s 0.35s cubic-bezier(.1, .72, .4, .97);
}

.card-hover:hover {
  background-size: cover;
  background-position: center;
  transform: scale(1);
  transition: 0.35s 0.1s transform cubic-bezier(.1, .72, .4, .97);
}

.card-hover:has(.card-hover__link:hover) .card-hover__extra {
  transform: translateY(0);
  transition: transform 0.35s;
}

.distance-info {
  background-color: black;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.distance-item {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
