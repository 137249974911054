<template>
  <div>
    <div>
      <v-toolbar color="#222222" prominent elevation="5">
        <img :src="logo" class="toolbar-icon" />
        <v-toolbar-title>Menü</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-export</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-card class="mx-auto pa-2">
          <v-list>
            <v-list-item
              prepend-avatar="https://media.licdn.com/dms/image/D4E03AQFEBtBRuekeGg/profile-displayphoto-shrink_200_200/0/1678097496773?e=1718236800&v=beta&t=tYO-YiS-KRIoykWMLqVkg_FsF5fSd0dAF-c_fwg8rC0"
              subtitle="khizar.alam@pinestack.io"
              title="Khizar Alam"
            ></v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              :value="item"
              color="primary"
              rounded="xl"
              @click="goToMenuItem(item.text)"
            >
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>

              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { getToolBarLogo } from "@/assets/Branding/branding.js";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: "MenuView",
  components: {},
  setup() {
    const router = useRouter();
    const goToMenuItem = (item) => {
      switch (item) {
        case "Profile":
          console.log("Profile clicked");
          break;
        case "Room Control":
          router.push({ name: 'RoomControl' }).catch((error) => {console.log(error)});
          break;
        case "Analytics":
          console.log("Analytics clicked");
          break;
        case "Language":
          console.log("Language clicked");
          break;
        case "Admin Panel":
          console.log("Admin Panel clicked");
          break;
        case "Booking Management":
          console.log("Booking Management clicked");
          break;
        case "Privacy":
          console.log("Privacy clicked");
          break;
        case "Help":
          console.log("Help clicked");
          break;
        case "Logout":
          console.log("Logout clicked");
          break;
        default:
          console.log("Unknown item clicked");
          break;
      }
    };

    const items = ref([
      { text: "Profile", icon: "mdi-account"},
      { text: "Room Control", icon: "mdi-lightbulb-on"},
      { text: "Analytics", icon: "mdi-chart-bar" },
      { text: "Language", icon: "mdi-translate" },
      { text: "Admin Panel", icon: "mdi-shield-account" },
      { text: "Booking Management", icon: "mdi-calendar-text" },
      { text: "Privacy", icon: "mdi-lock" },
      { text: "Help", icon: "mdi-help-circle" },
      { text: "Logout", icon: "mdi-logout" },
    ]);
    const logo = ref("");

    const openTestingDialog = () => {
      console.log("Open testing dialog"); // Replace with dialog opening logic
    };

    const showHelpDialog = ref(false);

    onMounted(() => {
      logo.value = getToolBarLogo();
    });

    return {
      logo,
      items,
      openTestingDialog,
      showHelpDialog,
      goToMenuItem,
      router,
    };
  },
});
</script>

<style scoped>
.menu-container {
  max-width: 400px;
  /* Adjust based on your design */
}
.v-list-item {
  transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98),
    box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);

  cursor: pointer;
  padding: 10px 30px; /* Adjust padding as needed */
}

.v-list-item:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Adjust hover color as needed */
}

/* Touchscreen adjustments */
@media (hover: hover) and (pointer: fine) {
  .v-list-item:hover {
    transform: scale(1.03); /* Slight increase for hover effect */
  }
}

@media (hover: none) and (pointer: coarse) {
  .v-list-item {
    padding: 15px 30px; /* Larger padding for easier touch */
  }
}
.toolbar-icon {
  height: 30px;
  align-self: center;
  margin-left: 1rem;
}

/* Additional styling */
</style>
