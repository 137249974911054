<template>
  <div class="cotp-group">
    <!-- Loader that stops when iframe is loaded -->
    <Loader v-if="showLoader" />

    <!-- Toolbar with back button -->
    <v-toolbar color="#222222" flat dense class="mb-0" style="cursor: pointer">
      <v-icon color="#077a2d" class="ml-2" size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
      <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">
        {{ "COTP Group" }}
      </v-toolbar-title>
    </v-toolbar>

    <!-- Responsive iframe for embedding the website -->
    <div class="iframe-container">
      <iframe
        :src="websiteUrl"
        class="iframe-content"
        frameborder="0"
        @load="onIframeLoad"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import Loader from "@/components/Loader.vue";

export default {
  name: "CotpGroup",
  components: {
    Loader,
  },
  setup() {
    const showLoader = ref(true);
    const router = useRouter();
    const websiteUrl = ref("https://cotp.group/");

    const goBack = () => {
      router.go(-1);
    };

    const onIframeLoad = () => {
      // Hide the loader once iframe is fully loaded
      showLoader.value = false;
    };

    return {
      showLoader,
      goBack,
      websiteUrl,
      onIframeLoad,
    };
  },
};
</script>

<style scoped>
.cotp-group {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.iframe-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: calc(100vh - 56px); /* Adjust height to avoid gap between toolbar and iframe */
}

.iframe-content {
  width: 100%;
  height: 100%;
  border: none;
}

/* Add responsiveness for smaller screens */
@media (max-width: 768px) {
  .v-toolbar-title {
    font-size: 20px;
  }
}
</style>
