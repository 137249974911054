<template>
  <div class="projects-list">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-2">
      <v-icon color="#077a2d" class="ml-2" size="40" @click="router.go(-1)">
        mdi-arrow-left-bold-circle
      </v-icon>
      <v-toolbar-title
        style="font-family: 'Material Design Icons'; font-size: 25px !important"
      >
        <img
          :src="require('@/assets/Branding/Artinvest/esg_logo_trans.png')"
          contain
          style="width: 230px; height: 100px"
        />
      </v-toolbar-title>
    </v-toolbar>
    <transition name="fade" mode="out-in">
      <div style="margin-left: 20px; margin-right: -15px">
        <div
          style="
            padding-bottom: 56.25%;
            position: relative;
            display: block;
            width: 100%;
          "
        >
          <iframe
            id="esg_iframe"
            width="100%"
            height="100%"
            src="https://www.art-invest.de/en/sustainability/"
            frameborder="0"
            allowfullscreen=""
            style="position: absolute; top: 0; left: 0"
          >
          </iframe>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, nextTick, onUpdated } from "vue";
import Loader from "@/components/Loader.vue";
import { useRouter } from "vue-router";

export default {
  name: "ESG",
  components: {
    Loader,
  },
  setup() {
    const router = useRouter();
    onMounted(() => {
      document.body.style.overflow = "hidden"; // Hide scrollbar when component mounts
    });

    return {
      router,
    };
  },
};
</script>

<style scoped>
.projects-list {
  overflow: hidden;
}
</style>
