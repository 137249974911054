import artinvestBlackCard from '@/assets/Branding/Artinvest/HomeViewPictures/artinvest-black-card.png';
import oldWall40 from '@/assets/Branding/Artinvest/HomeViewPictures/old_wall_40.jpg';
import showroom from '@/assets/Branding/Artinvest/HomeViewPictures/showroom.png';
import hotel from '@/assets/Branding/Artinvest/HomeViewPictures/hotel.png';
import digitalization from '@/assets/Branding/Artinvest/HomeViewPictures/digitalization.jpg';
import floorplan from '@/assets/Branding/Artinvest/HomeViewPictures/floorplan.jpg';
import livewebcams from '@/assets/Branding/Artinvest/HomeViewPictures/livewebcams.jpg';
import restaurants from '@/assets/Branding/Artinvest/HomeViewPictures/restaurant.png';
import veomo from '@/assets/Branding/Artinvest/HomeViewPictures/veomo.png';
import team from '@/assets/Branding/Artinvest/HomeViewPictures/team.png';

export let getHomeScreenData = () => [
  {
    id: 1,
    title: 'ART-INVEST',
    subtitle: 'ZWISCHEN ZWEI WELTEN',
    description: 'Alter Wall, 20457 Hamburg; Innenstadt (Deutschland)',
    image: artinvestBlackCard,
    creationTime: '2023-10-18',
  },
  {
    id: 2,
    title: 'ALTER WALL',
    subtitle: 'ENTWICKLUNG EINES URBANEN VIERTELS IM ZENTRUM VON LONDON',
    description: 'Canada Water, London SE1',
    image: oldWall40,
    creationTime: '2023-10-25',
  },
  {
    id: 3,
    title: 'SHOWROOM',
    subtitle: 'DIGITAL WIRD REAL',
    description: 'Stadtdeich/ Banksstraße 4, 20097 Hamburg',
    image: showroom,
    creationTime: '2023-10-29',
  },
  {
    id: 4,
    title: 'HOTELS',
    subtitle: 'FUTURISTISCHES GLASGEBÄUDE IN HISTORISCHER IMMOBILIE',
    description: 'Friedrichstadt, 40217 Düsseldorf',
    image: hotel,
    creationTime: '2023-11-02',
  },
  {
    id: 5,
    title: 'DIGITALISIERUNG',
    subtitle: 'DIE ARBEITSUMGEBUNG VON MORGEN',
    description: 'Schanzenstraße, 51063 Köln-Mülheim (Köln, Deutschland)',
    image: digitalization,
    creationTime: '2023-11-15',
  },
  {
    id: 6,
    title: 'ALTER WALL GRUNDRISSE',
    subtitle: 'BÜROFLÄCHE MIT DEN BESTEN AUSSICHTEN',
    description: 'Oberlindau 54-56, 60323 Frankfurt (Deutschland)',
    image: floorplan,
    creationTime: '2023-11-15',
  },
  {
    id: 7,
    title: 'LIVE-WEBCAMS',
    subtitle: 'EIN MEILENSTEIN FÜR BONN',
    description: 'Bundeskanzlerplatz, 53113 Bonn; Stadtzentrum (Bonn-Gronau)',
    image: livewebcams,
    creationTime: '2023-11-15',
  },
  {
    id: 8,
    title: 'RESTAURANTS',
    subtitle: 'DER NEUE KULINARISCHE HOTSPOT',
    description: 'Hohenzollernring 70, 50672 Köln (Deutschland)',
    image: restaurants,
    creationTime: '2023-11-15',
  },
  {
    id: 9,
    title: 'ART-INVEST TEAM',
    subtitle: 'DIE NEUE ART ZU LEBEN',
    description: 'Königsallee 1, 40212 Düsseldorf (Deutschland)',
    image: team,
    creationTime: '2023-11-15',
  },
  {
    id: 10,
    title: 'ÖPNV',
    subtitle: 'DIE NEUE ART ZU LEBEN',
    description: 'Königsallee 1, 40212 Düsseldorf (Deutschland)',
    image: veomo,
    creationTime: '2023-11-15',
  }
];
