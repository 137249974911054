<template>
    <div>
        <!-- Your component content goes here -->
    </div>
</template>

<script>
export default {
    name: 'ArtInvestTeamView',
    // Your component logic goes here
}
</script>

<style scoped>
/* Your component styles go here */
</style>