<template>
  <div class="fill-height" fluid>
    <v-img 
    :src="backgroundImage" 
    class="background-image"
    gradient="rgba(0,0,0,0.5), rgba(0,0,0,0.5)"
  ></v-img>
  <v-row class="auth-row" align="end" justify="center">
    <v-col cols="10" sm="5" md="3" lg="3">
        <transition name="fade" mode="out-in">
          <v-card 
            key="auth-card"
            class="elevation-12 shadowed-card" 
            color="#BDBDBD"
            dark
            v-if="showCard"
          >
          <v-row justify="center">
            <v-col cols="mx-auto">
              <v-img src="@/assets/Branding/Artinvest/artinvest-logo.png" />
            </v-col>
          </v-row>
          <v-card-text class="text-center">
            <v-btn class="login-btn" depressed @click="login">
              <v-icon size="22" class="login-icon">mdi-lock</v-icon>
              Authenticate
            </v-btn>
          </v-card-text>          
          </v-card>
        </transition>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useOAuthService } from '@/services/OAuthService';
import { getLoginBackgroundImage } from '@/assets/Branding/branding.js';

export default {
  name: 'LoginPage',
  setup() {
    const { authenticate } = useOAuthService();
    const showCard = ref(true);
    const backgroundImage = ref('');

    onMounted(async () => {
      document.body.style.overflow = 'hidden';
      let loginImage = await getLoginBackgroundImage();
      if (loginImage) {
        backgroundImage.value = loginImage;
      }
    
    });

    onBeforeUnmount(() => {
      document.body.style.overflow = '';
    });

    const login = () => {
      authenticate();
    };

    return { login, showCard, backgroundImage};
  },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.auth-row {
  height: 100%;
  display: flex;
  align-items: center; /* This will vertically center your card in the auth-row */
  justify-content: center;
}

.shadowed-card {
  border-radius: 16px;
  max-width: 600px; /* Control the max width but allow it to be smaller on mobile */
  width: 100%; /* Add this to make it responsive */
  margin: auto; /* Center the card */
  padding: 2rem;
  transition: transform 0.3s ease-in-out;
}

.shadowed-card:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 28px rgba(0,0,0,0.4), 0 2px 4px rgba(0,0,0,0.2), inset 0 0 0 1px rgba(255,255,255,0.1);
}

.v-card__title {
  font-size: 1.5rem; /* Adjust font size for scalability */
  font-weight: 300;
  text-align: center;
  margin-bottom: 1.5rem;
}

.v-card__subtitle {
  text-align: center;
  margin-bottom: 1.5rem;
}

.login-btn {
  background-color: #333;
  color: #fff;
  font-family: 'Roboto', sans-serif; /* Replace with your preferred font */
  font-weight: 500; /* Medium font weight for balance */
  text-transform: none; /* Keep text as is, do not capitalize */
  letter-spacing: 0.05rem; /* Add slight spacing between letters */
  padding: 0.75rem 1.75rem; /* Slightly more horizontal padding */
  border-radius: 10px; /* A little more rounded corners */
  box-shadow: 0 4px 6px rgba(0,0,0,0.2); /* A softer shadow */
  transition: all 0.2s ease-in-out; /* Transition for all properties for smoothness */
  display: inline-flex; /* To align icon and text */
  align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
}

.login-btn:hover {
  background: linear-gradient(to right, #4caf50, #8bc34a); /* Example gradient effect on hover */
  transform: translateY(-2px); /* Slight raise effect */
  box-shadow: 0 6px 12px rgba(0,0,0,0.3); /* Deeper shadow for 3D effect */
}

.login-icon {
  margin-right: 8px; /* Spacing between icon and text */
}


/* Ensuring text within buttons does not get automatically capitalized */
.login-btn span {
  text-transform: none;
}

.fill-height {
  position: relative;
  min-height: 100vh;
}

.background-image {
  position: fixed; /* Use fixed to cover the whole screen and stay in place on scroll */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw; /* Ensure it spans the full width */
  height: 100vh; /* Ensure it spans the full height */
  background-position: center; /* Center the background image */
  background-size: 100% 100%; 
  opacity: 0.4; /* Optional: Lower opacity if you want content to stand out more */
}

</style>


