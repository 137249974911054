<template>
  <div>
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-4">
      <v-icon style="margin-left: 10px" size="40" @click="goBack">
        mdi-arrow-left-bold-circle
      </v-icon>
      <v-toolbar-title
        style="font-family: 'Material Design Icons'; font-size: 25px !important"
      >
        {{ toobarTitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>
    <v-container class="main-content">
      <v-row justify="center">
        <v-col
          v-for="(card, index) in cards"
          :key="index"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card
            class="elegant-card"
            :class="{
              'active-card': selectedCard === index,
              'dim-card': selectedCard !== index && !card.hover,
            }"
            @mouseover="hoverCard(index, true)"
            @mouseleave="hoverCard(index, false)"
          >
            <img
              :src="card.image"
              class="card-image"
              :class="{ 'card-image-hover': card.hover }"
            />
            <div class="card-bottom">
              <v-card-title class="card-title">{{ card.text }}</v-card-title>
            </div>
            <v-btn
              color="#077a2d"
              class="presentation-button"
              @click="viewPdf(card.link)"
            >
              Präsentation starten
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- PDF Viewer Modal -->
    <div v-if="pdfDialog" class="modal-overlay" @click.self="closePdf">
      <div class="modal-content">
        <button class="modal-close" @click="closePdf">✖</button>
        <div class="pdf-viewer">
          <PDF
            v-if="pdfSrc"
            :src="pdfSrc"
            @onComplete="onPdfComplete"
            class="pdf-viewer-content"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, onUnmounted } from 'vue'
  import PDF from 'pdf-vue3'
  import Loader from '@/components/Loader.vue'
  import { getToolBarLogo } from '@/assets/Branding/branding.js'
  import { useRouter } from 'vue-router'
  import hoxtonalterwall from '@/assets/Branding/Artinvest/HotelViewPictures/hoxtonalterwall.jpg'
  import digitization from '@/assets/Branding/Artinvest/DigitalizationViewPictures/digitization.png'
  import hbk from '@/assets/Branding/Artinvest/DigitalizationViewPictures/hbk.jpeg'
  //import artinvestapproachpdf from "@/assets/Branding/Artinvest/DigitalizationViewPictures/artinvest-approach.pdf";
  //import digitizationPdf from "@/assets/pdfs/digitization.pdf";
  //import hbkPdf from "@/assets/pdfs/hbk.pdf";

  export default {
    name: 'DigitalizationView',
    components: {
      Loader,
      PDF,
    },
    setup() {
      const showLoader = ref(true)
      const logo = ref('')
      const router = useRouter()
      const pdfDialog = ref(false)
      const pdfSrc = ref('')
      const pdfLoading = ref(true)
      const toobarTitle = ref('D i g i t a l i s i e r u n g')
      const selectedCard = ref(null)

      const cards = ref([
        {
          text: 'Digitalisierung Projekt Alter Wall',
          image: hoxtonalterwall,
          hover: false,
          link: '/pdfs/artinvest-approach.pdf',
        },
        {
          text: 'Digitalisierung Erklärt',
          image: digitization,
          hover: false,
          link: '/pdfs/artinvest-explanation.pdf',
        },
        {
          text: 'Einwahl-HBK',
          image: hbk,
          hover: false,
          link: '/pdfs/dial-in-hbk.pdf',
        },
      ])

      const goBack = () => {
        router.go(-1)
      }

      const hoverCard = (index, hover) => {
        cards.value[index].hover = hover
      }

      const viewPdf = link => {
        const isPpt = link.endsWith('.ppt') || link.endsWith('.pptx')
        if (isPpt) {
          pdfSrc.value = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            link
          )}`
        } else {
          pdfSrc.value = link // For PDFs
        }
        pdfDialog.value = true
      }

      const closePdf = () => {
        pdfDialog.value = false
      }

      const onPdfComplete = () => {
        showLoader.value = false
      }

      const updateIsMobile = () => {
        const isMobile = window.innerWidth < 1024
      }

      window.addEventListener('resize', updateIsMobile)

      onMounted(async () => {
        logo.value = await getToolBarLogo()
        setTimeout(() => {
          showLoader.value = false
        }, 800)
        updateIsMobile()
      })

      onUnmounted(() => {
        window.removeEventListener('resize', updateIsMobile)
      })

      return {
        showLoader,
        logo,
        goBack,
        cards,
        hoverCard,
        viewPdf,
        closePdf,
        onPdfComplete,
        pdfDialog,
        pdfLoading,
        pdfSrc,
        toobarTitle,
      }
    },
  }
</script>

<style scoped>
  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-right: 20px;
  }

  .elegant-card {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s, filter 0.3s;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .elegant-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .active-card {
    filter: brightness(1);
  }

  .dim-card {
    filter: brightness(0.5);
  }

  .card-image {
    width: 100%;
    height: 70%;
    object-fit: cover;
    transition: transform 0.3s;
  }

  .card-image-hover {
    transform: scale(1.1);
  }

  .card-bottom {
    background-color: black;
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-title {
    color: white;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
  }

  .presentation-button {
    background-color: #077a2d !important; /* Set button color */
    color: white !important;
    cursor: pointer;
    /*margin: 10px; /* Add some margin for better spacing */
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .modal-content {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 100%; /* Full width */
    height: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .modal-close {
    position: absolute;
    top: -15px;
    right: -0.1px;
    background: #ffffff;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-close:hover {
    background: #f1f1f1;
  }

  .pdf-viewer {
    flex: 1;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure viewer takes full width */
  }

  .pdf-viewer-content {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>
