import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import AuthCallback from "@/views/auth/AuthCallback.vue";
import LoggedOut from "@/views/auth/LoggedOut.vue";
import Unauthorized from "@/views/auth/Unauthorized.vue";
import NoPermissionView from "@/views/NoPermissionView.vue";
import { useOAuthService } from "@/services/OAuthService.js";
import LoginPage from "@/views/LoginPage.vue";
import BuildingView from "@/views/BuildingView.vue";
import BookingView from "@/views/BookingView.vue";
import LocksView from "@/views/LocksView.vue";
import MenuView from "@/views/MenuView.vue";
import InfoCenterView from "@/views/InfoCenterView.vue";
import BookingPoisList from "@/components/BookingPoisList.vue";
import UserBookings from "@/components/UserBookings.vue";
import RoomControl from "@/components/RoomControl.vue";
import ArtInvestView from "@/views/ArtInvestView.vue";
import ProjectsList from "@/components/ProjectsList.vue";
import ProjectFilmsList from "@/components/ProjectFilmsList.vue";
import ESG from "@/components/ESG.vue";
import DigitalizationView from "@/views/DigitalizationView.vue";
import LiveWebcamsViewView from "@/views/LiveWebcamsViewView.vue";
import ArtInvestApproachComponent from "@/components/ArtInvestApproachComponent.vue";
import ArtInvestExplanationComponent from "@/components/ArtInvestExplanationComponent.vue";
import DialInHbkComponent from "@/components/DialInHbkComponent.vue";
import VeomoView from "@/views/VeomoView.vue";
import ShowroomView from "@/views/ShowroomView.vue";
import HotelView from "@/views/HotelView.vue";
import HoxtonAlterWallComponent from "@/components/HoxtonAlterWallComponent.vue";
import HoxtonTheBrandComponent from "@/components/HoxtonTheBrandComponent.vue";
import CotpGroupComponent from "@/components/CotpGroupComponent.vue";
import ArtInvestHotelsComponent from "@/components/ArtInvestHotelsComponent.vue";
import FloorPlansView from "@/views/FloorPlansView.vue";
import LiveWebcamsView from "@/views/LiveWebcamsView.vue";
import RestaurantsView from "@/views/RestaurantsView.vue";
import OldWallView from "@/views/OldWallView.vue";
import AlterWallHistoryComponent from "@/components/AlterWallHistoryComponent.vue";
import AlterWall232Component from "@/components/AlterWall232Component.vue";
import AlterWall38Component from "@/components/AlterWall38Component.vue";
import AlterWall40Component from "@/components/AlterWall40Component.vue";
import ArtInvestTeamView from "@/views/ArtInvestTeamView.vue";
import HamburgWebcamComponent from "@/components/HamburgWebcamComponent.vue";
import KolnWebcamComponent from "@/components/KolnWebcamComponent.vue";
import BonnWebcamComponent from "@/components/BonnWebcamComponent.vue";
import AlterWallFloorsView from "@/views/AlterWallFloorsView.vue";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/building",
    name: "BuildingView",
    component: BuildingView,
    meta: { requiresAuth: true },
  },
  { path: "/home", redirect: { name: "HomeView" } },
  {
    path: "/digitalization",
    name: "DigitalizationView",
    component: DigitalizationView,
    meta: { requiresAuth: true },
  },
  {
    path: "/home/digitalization/artInvestapproach",
    component: ArtInvestApproachComponent,
    name: "ArtInvestApproach",
    meta: { requiresAuth: true },
  },
  {
    path: "/home/digitalization/artInvestexplanation",
    component: ArtInvestExplanationComponent,
    name: "ArtInvestExplanation",
    meta: { requiresAuth: true },
  },
  {
    path: "/home/digitalization/dialinhbk",
    component: DialInHbkComponent,
    name: "DialInHbk",
    meta: { requiresAuth: true },
  },
  {
    path: "/alterwallfloors",
    name: "AlterWallFloorsView",
    component: AlterWallFloorsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/livewebcams",
    name: "LiveWebcamsViewView",
    component: LiveWebcamsViewView,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/home/livewebcams/hamburgwebcam",
  //   component: HamburgWebcamComponent,
  //   name: "HamburgWebcam",
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/home/livewebcams/kolnwebcam",
  //   component: KolnWebcamComponent,
  //   name: "KolnWebcam",
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/home/livewebcams/bonnwebcam",
  //   component: BonnWebcamComponent,
  //   name: "BonnWebcam",
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/team",
    name: "ArtInvestTeamView",
    component: ArtInvestTeamView,
    meta: { requiresAuth: true },
  },
  {
    path: "/veomo",
    name: "VeomoView",
    component: VeomoView,
    meta: { requiresAuth: true },

    children: [
      {
        path: "pois-list",
        component: BookingPoisList,
        name: "BookingPoisList",
        meta: { requiresAuth: true },
      },
      {
        path: "user-bookings",
        component: UserBookings,
        name: "UserBookings",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/showroom",
    name: "ShowroomView",
    component: ShowroomView,
    meta: { requiresAuth: true },
  },
  {
    path: "/hotels",
    name: "HotelView",
    component: HotelView,
    meta: { requiresAuth: true },
  },
  {
    path: "/home/hotels/hoxtonalterwall",
    component: HoxtonAlterWallComponent,
    name: "HoxtonAlterWall",
    meta: { requiresAuth: true },
  },
  {
    path: "/home/hotels/hoxtonbrand",
    component: HoxtonTheBrandComponent,
    name: "HoxtonTheBrand",
    meta: { requiresAuth: true },
  },
  {
    path: "/home/hotels/cotpgroup",
    component: CotpGroupComponent,
    name: "CotpGroup",
    meta: { requiresAuth: true },
  },
  {
    path: "/home/hotels/artinvesthotel",
    component: ArtInvestHotelsComponent,
    name: "ArtInvestHotels",
    meta: { requiresAuth: true },
  },
  {
    path: "/floorplans",
    name: "FloorPlansView",
    component: FloorPlansView,
    meta: { requiresAuth: true },
  },
  {
    path: "/livewebcams",
    name: "LiveWebcamsView",
    component: LiveWebcamsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/restaurants",
    name: "RestaurantsView",
    component: RestaurantsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/oldwall",
    name: "OldWallView",
    component: OldWallView,
    meta: { requiresAuth: true },
  },
  {
    path: "/home/oldwall/oldwallhistory",
    component: AlterWallHistoryComponent,
    name: "OldWallHistory",
    meta: { requiresAuth: true },
  },
  {
    path: "/home/oldwall/oldwall232",
    component: AlterWall232Component,
    name: "OldWall232",
    meta: { requiresAuth: true },
  },
  {
    path: "/home/oldwall/oldwall38",
    component: AlterWall38Component,
    name: "OldWall38",
    meta: { requiresAuth: true },
  },
  {
    path: "/home/oldwall/oldwall40",
    component: AlterWall40Component,
    name: "OldWall40",
    meta: { requiresAuth: true },
  },
  {
    path: "/locks",
    name: "LocksView",
    component: LocksView,
    meta: { requiresAuth: true },
  },
  {
    path: "/infocenter",
    name: "InfoCenterView",
    component: InfoCenterView,
    meta: { requiresAuth: true },
  },
  {
    path: "/menu",
    name: "MenuView",
    component: MenuView,
    meta: { requiresAuth: true },
  },
  {
    path: "/menu/room-control",
    component: RoomControl,
    name: "RoomControl",
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: { public: true },
  },
  {
    path: "/oauth2/code/callback",
    name: "auth-callback",
    component: AuthCallback,
    meta: { public: true },
  },
  {
    path: "/unauthorized-email",
    name: "unauthorized",
    component: Unauthorized,
    meta: { public: true },
  },
  {
    path: "/logged-out",
    name: "logged-out",
    component: LoggedOut,
    meta: { public: true },
  },
  {
    path: "/no-permission",
    name: "no-permission",
    component: NoPermissionView,
    meta: { requiresAuth: true },
  },
  {
    path: "/home/artinvest",
    component: ArtInvestView,
    name: "ArtInvestView",
    meta: { requiresAuth: true },
  },
  {
    path: "/home/artinvest/projects",
    component: ProjectsList,
    name: "ProjectsList",
    meta: { requiresAuth: true },
  },
  {
    path: "/home/artinvest/films",
    component: ProjectFilmsList,
    name: "ProjectFilmsList",
    meta: { requiresAuth: true },
  },
  {
    path: "/home/artinvest/esg",
    component: ESG,
    name: "ESG",
    meta: { requiresAuth: true },
  },
  { path: "/:pathMatch(.*)*", redirect: { name: "HomeView" } },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authService = useOAuthService();
  const loggedIn = await authService.isLoggedIn();

  if (to.meta.public || loggedIn) {
    next();
  } else if (!loggedIn && to.name !== "auth-callback") {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
