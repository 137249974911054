<template>
  <div class="infocenter">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-4">
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title
        style="font-family: 'Material Design Icons'; font-size: 25px !important"
        >{{ customerName }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container fluid class="py-6">
      <v-row justify="center">
        <v-col
          v-for="infoItem in infoItems"
          :key="infoItem.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card
            class="info-card"
            :class="{ 'info-card-hover': hoveredCard === infoItem.id }"
            @mouseover="hoveredCard = infoItem.id"
            @mouseleave="hoveredCard = null"
            @click="infoItemDetail(infoItem)"
          >
            <v-img :src="infoItem.image" class="white--text align-end" aspect-ratio="1.7">
              <!-- Placeholder is shown while the image is loading -->
              <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="white"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-title>{{ infoItem.title }}</v-card-title>
            <v-card-subtitle>{{ infoItem.subtitle }}</v-card-subtitle>
            <v-card-text class="text-description">{{ infoItem.description }}</v-card-text>
            <v-card-actions>
              <v-btn class="read-more-btn" @click="infoItemDetail(infoItem)">
                Read More
                <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ref, defineComponent, onMounted } from "vue";
import moment from "moment";
import Loader from "@/components/Loader.vue";
import { getToolBarLogo, getCustomerName } from "@/assets/Branding/branding.js";

export default defineComponent({
  name: "InfoCenterView",
  components: {
    Loader,
  },
  setup() {
    const hoveredCard = ref(null);
    const showLoader = ref(true);
    const customerName = ref("");
    const logo = ref("");
    const infoItems = ref([
  {
    id: 1,
    title: 'ALTER WALL, HAMBURG',
    subtitle: 'BETWEEN TWO WORLDS',
    description: 'Alter Wall, 20457 Hamburg; inner City (Germany)',
    image: 'https://www.art-invest.de/wp-content/uploads/2021/05/AlterWall_125_Anschnitt-700x420.jpg', // Replace with your image path
    creationTime: '2023-10-18',
  },
  {
    id: 2,
    title: 'DOCKSIDE CANADA WATER, LONDON',
    subtitle: 'CENTRAL LONDON URBAN QUARTER DEVELOPMENT',
    description: 'Canada Water, London SE1',
    image: 'https://www.art-invest.de/wp-content/uploads/2022/07/LUCIANR_CANADAWATER_WATERVIEW-02-768x432.jpg', // Replace with your image path
    creationTime: '2023-10-25',
  },
  {
    id: 3,
    title: 'HAMMERBROOKLYN, HAMBURG',
    subtitle: 'DIGITAL BECOMES REAL',
    description: 'Stadtdeich/ Banksstraße 4, 20097 Hamburg',
    image: 'https://www.ubm-development.com/magazin/wp-content/uploads/2021/08/D85_3103_Schwan_Hammerbrooklyn_PRINT-576x324.jpg', // Replace with your image path
    creationTime: '2023-10-29',
  },
  {
    id: 4,
    title: 'FÜRST & FRIEDRICH, DUSSELDORF',
    subtitle: 'FUTURISTIC GLASS BUILDING IN HISTORICAL PROPERTY',
    description: 'Friedrichstadt, 40217 Dusseldorf',
    image: 'https://sop-architekten.de/fileadmin/sop/content/Projekte/Friedrichstrasse_56/B_E_F_F_A_6_1920x1080.jpg', // Replace with your image path
    creationTime: '2023-11-02',
  },
  {
    id: 5,
    title: 'I/D COLOGNE, COLOGNE',
    subtitle: 'THE WORKING ENVIRONMENT OF TOMORROW',
    description: 'Schanzenstraße, 51063 Cologne-Mülheim (Cologne, Germany)',
    image: 'https://www.art-invest.de/wp-content/uploads/2020/06/nachtvisu-NEU-1024x555.jpg', // Replace with your image path
    creationTime: '2023-11-15',
  },
  {
    id: 6,
    title: 'MORROW, FRANKFURT',
    subtitle: 'OFFICE SPACE WITH THE BEST VIEWS',
    description: 'Oberlindau 54-56, 60323 Frankfurt (Germany)',
    image: 'https://www.art-invest.de/wp-content/uploads/2018/03/morrow_DIV5285-1536x1024.jpg', // Replace with your image path
    creationTime: '2023-11-15',
  },
  {
    id: 7,
    title: 'NEUER KANZLERPLATZ, BONN',
    subtitle: 'A MILESTONE FOR BONN',
    description: 'Bundeskanzlerplatz, 53113 Bonn; city centre (Bonn-Gronau)',
    image: 'https://www.art-invest.de/wp-content/uploads/2020/03/Art-Invest_Real_Estate_Neuer_Kanzlerplatz_Gesamtareal-2048x1536.jpg', // Replace with your image path
    creationTime: '2023-11-15',
  },
]);

    const infoItemDetail = (infoItem) => {
      console.log("Read more about:", infoItem);
      // Logic for handling "Read More" button click
    };
    

    onMounted(() => {
      logo.value = getToolBarLogo();
      customerName.value = getCustomerName();

     setTimeout(() => {
       showLoader.value = false;
     }, 1200);
    });

    return {
      hoveredCard,
      infoItems,
      infoItemDetail,
      showLoader,
      customerName,
      logo,
    };
  },
});
</script>

<style scoped>
.infocenter {
}
.toolbar-icon {
  height: 30px;
  align-self: center;
  margin-left: 1rem;
}

.info-card {
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.info-card-hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.v-card-title {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: white;
  font-size: 15px;
}

.v-card-subtitle {
  font-family: 'Roboto', sans-serif;
  color: #50C878;
  margin-bottom: 4px;
}

.text-description {
  font-family: 'Roboto', sans-serif;
  color: #666;
  font-size: 0.875rem;
}

.read-more-btn {
  font-family: 'Roboto', sans-serif;
  color: #1976D2;
  text-transform: none;
  font-weight: bold;
}

.read-more-btn v-icon {
  margin-left: 4px;
}

@media (max-width: 600px) {
  .info-card {
    margin-bottom: 16px;
  }
}
</style>